import { useEffect, useState } from 'react';
import '../Styles/App.css';
import '../Styles/Primer.scss';
import '../Styles/Bootstrap.scss';
import { AmplifySignIn, AmplifyAuthenticator, AmplifySignUp, AmplifyConfirmSignUp } from '@aws-amplify/ui-react';
import { AuthState } from '@aws-amplify/ui-components';
import { Box, Text, TextInput, ButtonPrimary, StyledOcticon, Link as PrimerLink } from '@primer/components';
import ShuvLogo from '../Media/shuv-logo.png';
import { useHistory, Link } from 'react-router-dom';
import { ArrowRightIcon } from '@primer/octicons-react';

function LoginPage(props){

    let history = useHistory();

    const [authState, setAuthState] = useState();

    useEffect(() => {
        setAuthState(props.authState)
        // Force the user back to the root level when logged out.
    //    if (props.authState === AuthState.SignedOut) {
    //        history.push('/');
    //        props.resetCb();
    //    }       
    },[props.authState])

    function signUpSubmit() {
        console.log('Signing up');
    }

    function renderAuthText() {
        switch(authState) {
            case AuthState.SignIn:
            case AuthState.ConfirmSignIn:
            case AuthState.VerifyingAttributes:
            case AuthState.ForgotPassword:
            case AuthState.ResetPassword:
            case AuthState.SettingMFA:
            case AuthState.VerifyContact:
            case AuthState.SignedOut:
                return <Text fontSize={50}>Sign in to Shuv</Text>
            case AuthState.SignUp:
            case AuthState.SigningUp:
                return <Text fontSize={50}>Sign up for Shuv</Text>
            case AuthState.ConfirmSignUp:
                return <Text fontSize={50}>Confirm Sign up</Text>
            default:
                return <div />
        }
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="center"> 
            <Box display="flex" pt="5" flexDirection="row" alignItems="center" justifyContent="center" flexWrap="wrap">
                <Box display="flex" p="3" flexDirection="column" alignItems="center">
                    <img src={ShuvLogo} alt="Shuv" height="200"/>
                    {renderAuthText()}
                </Box>
                <Box display="flex" flexDirection="column" m="3" className="content-box">          
                    <AmplifyAuthenticator>
                        <AmplifySignIn
                            headerText=""
                            hideSignUp="true"
                            slot="sign-in"
                            
                            formFields={[
                                {
                                    type: "username",
                                    label: "Username",
                                    placeholder: "Enter your username",
                                    inputProps: { required: true, autocomplete: "username" },
                                },
                                {
                                    type: "password",
                                    label: "Password",
                                    placeholder: "Enter your password",
                                    inputProps: { required: true, autocomplete: "username" },
                                }
                            ]}
                        /> 
                        <AmplifySignUp
                            slot="sign-up"
                            formFields={[
                                {
                                    type: "username",
                                    label: "Username *", 
                                    placeholder: "Choose a Username"
                                },
                                {
                                    type: "password",
                                    label: "Password *", 
                                    placeholder: "Create a password"
                                },
                                {
                                    type: "email",
                                    label: "Email Address *", 
                                    placeholder: "Your email address"
                                },
                                {
                                    type: "phone_number",
                                    label: "Phone Number *", 
                                    placeholder: "(555) 555-1212)"
                                }
                            ]}
                        />
                                        
                    </AmplifyAuthenticator> 
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                        <Text>New to Shuv?</Text>
                        <Link className="ml-1 link" to={'/'}>
                            <Text>Create an account</Text>
                        </Link>
                        <Text>.</Text>
                    </Box>
                </Box>
            </Box> 
            <Box p="4" mt="4" display="flex" flexDirection="row">
                <Text mr="1">
                    Shuv is a CI/CD tool from Loupe for managing the full lifecycle of PLC and IPC software projects. Check out our story 
                    <PrimerLink ml="1" className="f4" href="https://loupe.team/story/introducing-loupe-shuv">here</PrimerLink> 
                    .
                </Text> 
            </Box>      
        </Box>
    )
}

export default LoginPage;