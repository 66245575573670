import React from 'react';
import '../Styles/App.css';
import '../Styles/Globe.css';
import '../Styles/Bootstrap.scss';
import LatestBuild from './LatestBuild.js';
import ProjectComposition from './ProjectComposition.js';
import { Text, Flex, StyledOcticon, BorderBox, BranchName, Link as PrimerLink, Box } from '@primer/components';
import { LinkIcon, GitBranchIcon, ArrowDownIcon, ChevronLeftIcon, ChevronRightIcon, TriangleRightIcon, DotIcon, CommitIcon, RepoIcon} from '@primer/octicons-react';
import { Link } from 'react-router-dom';
import AsIcon from '../Media/as-icon.png';
import PuzzlePieceIcon from '../Media/puzzle-piece-icon.png';

function Project(props){

    return (
        <div className="project-details">
        {
            (props.mode === 'overview') ? (
                <div className="border-bottom">
                    <Flex alignItems="center" mt="5" justifyItems="center">
                        <img className="as-icon mr-3" src={AsIcon} alt="Shuv" height="50"/>      
                        <Link className="h2 mr-3 link" to={'/' + props.organization.name + '/pipelines/' + props.project.alias}>{props.project.alias}</Link>
                    </Flex>
                    <Flex mt="0">
                        <Text className="f3">{props.project.description}</Text>
                    </Flex> 
                    <ProjectComposition project={props.project} mode="overview" /> 
                    <Flex mt="2" mb="4">
                        <LatestBuild project={props.project} fontSize="f3"/>    
                    </Flex>                             
                </div>
            ) : (
                <div/>
            )
        }
        {
            (props.mode === 'header') ? (
                <div>
                    <Flex mt="0">
                        <Text mr="3" className="f3">{props.project.description}</Text>
                    </Flex>
                    <Flex mt="2">
                        <LatestBuild project={props.project} fontSize="f3"/>
                    </Flex>                               
                </div>
            ) : (
                <div/>
            )
        }
        {
            (props.mode === 'detail') ? (
                <Flex flexDirection="column">
                    <div className="mt-3">
                        <Text textAlign="left" className="h4">Pipeline Composition</Text>
                    </div>
                    <BorderBox mt="1">
                        <Flex px="3" pt="3" flexDirection="row" alignItems="center" justifyContent="center">                    
                            <StyledOcticon mr="1" icon={ChevronLeftIcon} size={65} color="icon.secondary" />
                            <ProjectComposition project={props.project} mode="detail"/>     
                            <StyledOcticon  mt="1" icon={ChevronRightIcon} size={65} color="icon.secondary" />                                            
                        </Flex>
                    </BorderBox>
                    <div className="mt-3">
                        <Text textAlign="left" className="h4">Latest Build</Text>
                    </div>
                    <BorderBox mt="1" mb="2">
                        <Flex p="1" alignItems="center" justifyContent="center">
                            <Text textAlign="left" mr="4">Commit:</Text>      
                            {
                                (props.project.latestBuild.commit !== '') ? (
                                    <Box>
                                        <StyledOcticon mr="1" icon={CommitIcon} size={25} color="icon.primary" />
                                        <PrimerLink className="f4" href={props.project.repository + "/commit/" + props.project.latestBuild.commit}>{props.project.latestBuild.commit.substring(0,7)}</PrimerLink>  
                                    </Box>
                                ) : (
                                    <Text>N/A</Text>
                                )
                            }          
                        </Flex> 
                        <Flex p="1" alignItems="center" justifyContent="center">
                            <Text textAlign="left" mr="4">Build Status:</Text> 
                            {
                                (props.project.latestBuild.status !== '') ? (
                                    <Text className="f4">{props.project.latestBuild.status}</Text> 
                                ) : (
                                    <Text>N/A</Text>
                                )
                            } 
                        </Flex> 
                        <Flex pt="1" pl="1" pb="3" alignItems="center" justifyContent="center">
                            <Text textAlign="left" mr="4">Build Stage:</Text>
                            <div>                        
                            {
                                (props.project.latestBuild.stage !== "") ? (
                                    <Text className="f4">{props.project.latestBuild.stage}</Text>
                                ) : (
                                    <Text className="f4">N/A</Text>
                                )
                            }                                                           
                            </div>
                        </Flex> 

                    </BorderBox>
                </Flex>
            ) : (
                <div/>
            )         
        }
        </div>


    )
}


export default Project;