import React, { useEffect, useState, useRef } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import Globe from 'react-globe.gl';
import * as THREE from 'three';
import * as topojson from 'topojson';
import { Box, Label } from '@primer/components';

function MachineGlobe(props) {

  const polygonsMaterial = new THREE.MeshLambertMaterial({ color: 'white', side: THREE.DoubleSide });
  const [landPolygons, setLandPolygons] = useState([]);
  const [places, setPlaces] = useState([])
  const globeEl = useRef();

  useEffect(() => {
    // load globe surface data.
    fetch('//unpkg.com/world-atlas/land-110m.json').then(res => res.json())
      .then(landTopo => {
        setLandPolygons(topojson.feature(landTopo, landTopo.objects.land).features);
      });
    
    var tempPlaces = []
    if (props.machines.length > 0) {
      props.machines.forEach((machine) => {
        if ((machine.longitude !== 0) && (machine.latitude !== 0)) {
          var latitude = parseInt(machine.latitude) + 10;
          tempPlaces.push({
            latitude: machine.latitude,
            longitude: machine.longitude,
            name: machine.name,  
            color: machine.online ? "green" : "grey"        
          })
        }        
      })
    }
    console.log('Places: ', tempPlaces)
    setPlaces(tempPlaces)
    // Focus on first machine in list. 
    if (tempPlaces.length > 0) {
      globeEl.current.pointOfView({ lat: tempPlaces[0].latitude, lng: tempPlaces[0].longitude, altitude: 2 }, 3000)
    }
  }, []);   

  function createLabel(name, color) {
    const el = document.createElement('span');
    el.innerHTML = name;
    el.style.backgroundColor = color;
    el.style.borderRadius = '15px';
    el.style.padding = '5px';
    return el;
  }

  return (
    <Box alignSelf="center">
      <Globe
        ref={globeEl}
        width={props.size}
        height={props.size}
        backgroundColor="rgba(0,0,0,0)"
        showGlobe={true}
        showAtmosphere={true}
        atmosphereColor="white"
        polygonsData={landPolygons}
        polygonCapMaterial={polygonsMaterial}
        polygonSideColor={() => 'rgba(0, 0, 0, 0)'}

        // htmlElementsData={places}
        // htmlLat={d => d.labelLatitude}
        // htmlLng={d => d.labelLongitude}
        // htmlAltitude={0.01}
        // htmlElement={d => { return createLabel(d.name, d.color) }}

        // pointsData={places}
        // pointLabel={d => d.name}
        // pointAltitude={0.01}
        // pointLat={d => d.latitude}
        // pointLng={d => d.longitude}
        // pointColor={d => d.color}
        // pointRadius={1}


        labelsData={places}
        labelLat={d => d.latitude}
        labelLng={d => d.longitude}
        labelAltitude={0.01}
        labelText={d => d.name}
        labelSize={3}
        labelDotRadius={1}
        labelIncludeDot={true}
        labelColor={d => d.color}
        labelResolution={2}
      />
    </Box>
  )

}

export default MachineGlobe;