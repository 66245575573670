import React, { useState, useEffect } from 'react';
import '../Styles/App.css';
import '../Styles/Primer.scss';
import Project from '../Components/Project.js';
import Machine from '../Components/Machine.js';
import AsNtpSetup from '../Media/as-ntp-setup.png';
import AsEthSetup from '../Media/as-eth-setup.png';
import AsDnsSetup from '../Media/as-dns-setup.png';
import AsFileDeviceSetup from '../Media/as-file-device-setup.png';
import VersionLabel from '../Components/VersionLabel';
import MachineGlobe from '../Components/MachineGlobe';
//import '../Styles/Bootstrap.scss';
import { Breadcrumb, CounterLabel, Pagehead, Text, Flex, Header, StyledOcticon, Avatar, UnderlineNav, Box, Link as PrimerLink, SideNav, TextInput, ButtonInvisible, CircleOcticon, ButtonDanger, Spinner, Label } from '@primer/components';
import { PlugIcon, CopyIcon, FileIcon, RepoPushIcon, ArrowRightIcon, TagIcon, PackageDependentsIcon, SyncIcon, GitBranchIcon, HomeIcon, ContainerIcon, GearIcon, AlertIcon, PackageIcon, SquirrelIcon, FileZipIcon, FileBinaryIcon, NoteIcon, FileBadgeIcon, BeakerIcon, VideoIcon, SearchIcon } from '@primer/octicons-react';
import { useRouteMatch, withRouter, Link, Switch, Route, useHistory, useParams } from 'react-router-dom';
import DevopsAdmin from '../Media/devops-admin.png';
import raw from "raw.macro";
import awsExports from "../aws-exports";
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { Accordion, Button } from 'react-bootstrap';
import $ from "jquery";
import RepositoryType from '../Components/RepositoryType';
import MessagePopup from '../Components/MessagePopup';


function ProjectDetailPage(props){

    let { path, url } = useRouteMatch();

    const [activeProject, setActiveProject] = useState();
    const [projectMachines, setProjectMachines] = useState([]);
    const [shuvContentSignedUrl, setShuvContentSignedUrl] = useState()

    useEffect(() => {
        var localMachines = [];
        const pathSections = props.location.pathname.split('/');

        console.log('PATH', pathSections)
        props.projects.forEach( function(project, index) { 
            // Expected pathname format:
            // / <org> / projects / <project> / <subsection>
            if (project.alias === pathSections[3]) {
                setActiveProject(project);
                if (props.machines.length > 0) {
                    props.machines.forEach( function(machine, index) {
                        if (machine.project.alias === project.alias) {
                            localMachines.push(machine);
                        }
                    })
                }
                setProjectMachines(localMachines);
            }                             
        })

        getShuvContentSignedUrl(pathSections[3]);

    }, [props.projects, props.machines])

    let { activeOrg } = useParams();
    useEffect(() => {
        props.updateActiveOrganizationIndexCb(activeOrg);
    },[activeOrg])

    async function getShuvContentSignedUrl(projectAlias) {
        console.log('Configuring storage')
        const configuration = {
            level: 'public', 
            customPrefix: {
                public: props.filePath + props.organization.name + '/' + projectAlias
            }
        };
        const result = await Storage.get('/artifacts/Shuv.zip', configuration);
        setShuvContentSignedUrl(result);
    }

    return (
        <div>
            <Pagehead py="0" my="2">
                <Breadcrumb m={2}>
                    <Breadcrumb.Item className="h2">
                        <Link className="link" to="/">
                            <Avatar mr={2} square size={50} src={props.organization.avatar}/>
                            <Text>{props.organization.name}</Text>
                        </Link>             
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className="h2">
                        <Link className="link" to={"/" + props.organization.name}>
                            <Text>Pipelines</Text>
                        </Link>          
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className="h2" selected>
                    {
                        (activeProject) ? (
                            <Text>{activeProject.alias}</Text>
                        ) : (
                            <Text />
                        )
                    }                          
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="ml-4">
                    {
                        (activeProject) ? (
                            <Project
                                key={activeProject.alias} 
                                organization={props.organization}
                                project={activeProject} 
                                mode="header"
                            />
                        ) : (
                            <Text />
                        )
                    }
                </div>
                <UnderlineNav mt="2" aria-label="Main">
                    <UnderlineNav.Link sx={{borderBottomWidth: 5}} ml="3" selected={props.location.pathname === `${url}`}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <StyledOcticon mr="2" icon={HomeIcon} size={20} color="icon.secondary" />  
                            <Link to={`${url}`}>
                                <Text fontSize="3" color="text.primary">Details</Text>
                            </Link>
                        </Box>
                    </UnderlineNav.Link>
                    <UnderlineNav.Link sx={{borderBottomWidth: 5}} selected={props.location.pathname === `${url}/machines`}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <StyledOcticon mr="2" icon={ContainerIcon} size={20} color="icon.secondary" />  
                            <Link to={`${url}/machines`}>
                                <Text fontSize="3" color="text.primary">Machines</Text>
                            </Link>
                            <CounterLabel ml={2}>{props.machines.length}</CounterLabel>
                        </Box>
                    </UnderlineNav.Link>
                    <UnderlineNav.Link sx={{borderBottomWidth: 5}} selected={props.location.pathname === `${url}/packages`}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <StyledOcticon mr="2" icon={PackageIcon} size={20} color="icon.secondary" />  
                            <Link to={`${url}/packages`}>
                                <Text fontSize="3" color="text.primary">Packages</Text>
                            </Link>   
                        </Box>      
                    </UnderlineNav.Link>
                    <UnderlineNav.Link sx={{borderBottomWidth: 5}} selected={props.location.pathname === `${url}/settings`}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <StyledOcticon mr="2" icon={GearIcon} size={20} color="icon.secondary" />  
                            <Link to={`${url}/settings`}>
                                <Text fontSize="3" color="text.primary">Settings</Text>
                            </Link>   
                        </Box>      
                    </UnderlineNav.Link>
                </UnderlineNav>
            </Pagehead>
            {
                (activeProject) ? (
                    <Switch>
                        <Route exact path={path}>
                            <ProjectOverview 
                                organization={props.organization}
                                activeProject={activeProject}
                            />
                        </Route>
                        <Route path={`${path}/machines`}>
                            <ProjectMachines 
                                organization={props.organization}
                                machines={projectMachines}
                            />
                        </Route>
                        <Route path={`${path}/packages`}>
                            <ProjectPackages 
                                organization={props.organization}
                                machines={projectMachines}
                                location={props.location}
                                filePath={props.filePath}
                                activeProject={activeProject}
                            />
                        </Route>
                        <Route path={`${path}/settings`}>
                            <ProjectSettings
                                organization={props.organization}
                                activeProject={activeProject}
                                location={props.location}
                                shuvContentSignedUrl={shuvContentSignedUrl}
                                updateOrganizationsCb={props.updateOrganizationsCb}
                            />
                        </Route>
                    </Switch>
                ) : (
                    <div />
                )
            }
                             
        </div>
    )
}

function ProjectOverview(props) {

    return (
        <Flex flexDirection="column" alignItems="center">  
        {                      
            (props.activeProject) ? (
                <Project 
                    key={props.activeProject.alias} 
                    organization={props.organization}
                    project={props.activeProject} 
                    mode="detail"
                />
            ) : (
                <span>Please wait, loading...</span>
            )
        }
        </Flex> 
    )

}

function ProjectMachines(props) {

    return (
        <Box>
        {
            (props.machines.length > 0) ? (
                <Flex flexDirection="column" alignItems="center">
                    <Flex className="machine-list" flexDirection="column" alignItems="center">
                    {             
                        props.machines.map((machine, index) => (
                            <Machine
                                key={index}
                                organization={props.organization}
                                machine={machine}
                                mode='overview'
                            />
                        ))
                    }
                    </Flex> 
                    <Flex className="globe">   
                        <MachineGlobe machines={props.machines} size={400} />                         
                    </Flex> 
                </Flex> 
            ) : (
                <Box mt="5" p="5" display="flex" flexDirection="column" alignItems="center">
                    <StyledOcticon icon={PlugIcon} size={35} />
                    <Text as="h3" textAlign="center" mb="1">This pipeline doesn't have any machines associated with it.</Text>
                    <Box display="flex"> 
                        <Text mr="1" as="p">You can browse the existing</Text>
                        <Link className="link" to={"/" + props.organization.name}>
                            pipelines
                        </Link>
                        <Text mx="1" as="p">or</Text>
                        <Link className="link" to={"/" + props.organization.name + "/machines"}>
                            machines
                        </Link>
                        <Text ml="1" as="p">to get started.</Text>
                    </Box>
                </Box>
            )
        } 
        </Box>
    )
}

function ProjectPackages(props) {

    const [buildList, setBuildList] = useState([])
    const [listInitialized, setListInitialized] = useState(false)

    useEffect(() => {      
        getBuildPackageSignedUrl()
    }, [props.activeProject.latestBuild.stage])

    function refreshArtifactList() {
        setListInitialized(false)
        getBuildPackageSignedUrl()
    }
    
    async function getBuildPackageSignedUrl() {
        const pathSections = props.location.pathname.split('/');
        const projectAlias = pathSections[3]
        console.log('Listing storage')
        var configuration = {
            level: 'public', 
            customPrefix: {
                public: props.filePath + props.organization.name + '/' + projectAlias
            }
        };
        const results1 = await Storage.list('', configuration);
        console.log('List results', results1);

        // Determine the versions that are present (first-level subfolders).
        var pipelineBuilds = [] 
        for (let i = 0; i < results1.length; i++) {
            const path = results1[i].key.split('/')
            if ((!pipelineBuilds.some(e => e.version === path[1])) && (path[1] != 'artifacts')) {
                // Extract the lastModified info and format to desired timezone. 
                const localizedDate = convertTZ(results1[i].lastModified, 'America/Los_Angeles')
                // For each version, grab all of its assets. 
                var build = {
                    version: path[1],
                    lastModified: localizedDate,
                    result: '',
                    logs: [],
                    binaries: [],
                    testsPretty: [],
                    testsRaw: [],
                    videos: [],
                    misc: []
                }
                const resultsMore = await Storage.list('/' + path[1] + '/', configuration);
                for (let j = 0; j < resultsMore.length; j++) {
                    // Place the asset in the correct bucket.
                    const itemPath = resultsMore[j].key.split('/')
                    const itemData = {
                        name: itemPath[2],
                        key: resultsMore[j].key,
                        size: resultsMore[j].size
                    }
                    if (itemPath[2].endsWith('.log')) {
                        build.logs.push(itemData);
                    } else if (itemPath[2].endsWith('.exe') || itemPath[2].endsWith('.tar.gz') || itemPath[2].endsWith('.zip')) {
                        build.binaries.push(itemData);
                    } else if (itemPath[2].endsWith('.xml')) {
                        build.testsRaw.push(itemData);
                    } else if (itemPath[2].endsWith('.html')) {
                        build.testsPretty.push(itemData);
                    } else if (itemPath[2].endsWith('.mp4')) {
                        build.videos.push(itemData)
                    } else {
                        build.misc.push(itemData);
                    }
                    // Look for the special item called "PipelineResult.log". Read its contents to check the status of the build. 
                    if (itemPath[2].includes("PipelineResult")) {
                        configuration.download = true;
                        const resultFile = await Storage.get(resultsMore[j].key, configuration);
                        const resultFileRawData = await resultFile.Body.text()
                        const resultFileJsonStruct = JSON.parse(resultFileRawData)
                        build.result = resultFileJsonStruct["buildResult"]
                    }
                }
                pipelineBuilds.push(build)
            }
        }

        // Now sort the file list by timestamp. 
        const sortedPipelineBuilds = pipelineBuilds.sort(function(a, b) {
            return new Date(b.lastModified) - new Date(a.lastModified);
        })
        setBuildList(sortedPipelineBuilds)
        console.log('Sorted list' , sortedPipelineBuilds)
        setListInitialized(true)
    }

    function convertTZ(date, tzString) {
        const new_date = new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
        const split_date = String(new_date).split('(')
        return split_date[0]
    }

    function determineFileSize(size) {
        if (size < 1000000) {
            return <Text>&lt; 1 MB</Text>
        } else {
            return <Text>{Math.trunc(size / 100000) / 10} MB</Text>
        }
    }

    async function downloadObject(objectKey) {
        const pathSections = props.location.pathname.split('/');
        var configuration = {
            level: 'public', 
            customPrefix: {
                public: props.filePath + props.organization.name + '/' + pathSections[3]
            }
        };
        const objectUrl = await Storage.get(objectKey, configuration);
        window.open(objectUrl)
    }

    function listMapper(list, icon) {
        return (
            list.map((artifact, index) => (
                <Box mt="2" display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                    <Box>
                        <StyledOcticon mr="1" icon={icon} size={25} color="icon.primary" />
                        <PrimerLink mr="6" className="f4" onClick={() => downloadObject(artifact.key)}>{artifact.name}</PrimerLink>
                    </Box>
                    {determineFileSize(artifact.size)}
                </Box>     
            ))
        )
    }

    function getBuildStatusClasses(result) {
        var classes = []
        classes.push('border-bottom')
        if ((result === 'FAILURE') || (result === 'ABORTED')) {
            classes.push('failed-status')
        } else if (result === 'UNSTABLE') {
            classes.push('unstable-status')
        }
        console.log(result)
        console.log(classes)
        return classes.join(' ')
    }

    return (
        <Box mt="5" display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center" >
            {   
                (buildList.length > 0) && (listInitialized) ? (
                    <Box display="flex" flexDirection="column" className="package-list">
                        <Box display="flex" flexDirection="row" justifyContent="flex-end">
                            <ButtonInvisible onClick={() => refreshArtifactList()}>
                                <StyledOcticon color="icon.info" icon={SyncIcon} size={30} />
                            </ButtonInvisible>
                        </Box>
                        {
                            buildList.map((build, index) => (
                                <Box mt="4" pb="4" pl="4" className={getBuildStatusClasses(build.result)}>
                                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                        <VersionLabel version={build.version} repo={props.activeProject.repository}/>
                                        {
                                            (index === 0) ? (
                                                <Label variant="large" outline sx={{borderColor: 'border.success', color: 'text.success'}} className="f4">Latest</Label>
                                            ) : (
                                                <Box />
                                            )
                                        }
                                        <Text>{build.lastModified}</Text>
                                    </Box> 
                                    {listMapper(build.logs, NoteIcon)}
                                    {listMapper(build.testsPretty, FileBadgeIcon)}
                                    {listMapper(build.testsRaw, BeakerIcon)}
                                    {listMapper(build.binaries, PackageIcon)}
                                    {listMapper(build.videos, VideoIcon)}
                                    {listMapper(build.misc, FileIcon)}
                                </Box>   
                            ))
                        }
                    </Box>
                ) : (
                    (listInitialized) ? (
                        <Box p="5" display="flex" flexDirection="column" alignItems="center">
                            <StyledOcticon icon={SquirrelIcon} size={35} />
                            <Text as="h3" textAlign="center" mb="1">This pipeline doesn't have any packages associated with it.</Text>
                            <Box display="flex"> 
                                <Text mr="1" as="p">You can trigger a build by pushing changes to build its first package.</Text>
                            </Box>
                        </Box>
                    ) : (
                        <Box p="5" display="flex" flexDirection="column" alignItems="center">
                            <Spinner size="medium" />
                            <Text as="h3" textAlign="center" mb="1">Loading packages, please wait...</Text>
                        </Box>
                    )
                )
            }
        </Box>       
    )
}

function ProjectSettings(props) {

    let { path, url } = useRouteMatch();

    const history = useHistory();

    useEffect(() => {
        if (props.activeProject.trigger === 'branch') {
            $("#trigger-branch").trigger("click")
        } else {
            $("#trigger-tag").trigger("click")
        }   
    }, [history.location.pathname])

    function downloadJenkinsfile() {
        // Retrieve the Jenkinsfile for processing.
        var text;
        if (props.activeProject.type == "as") {
            text = raw("../Templates/AsJenkinsfile") 
        }    
        else if (props.activeProject.type == 'tc') {
            text = raw("../Templates/TcJenkinsfile")
        }

        // Prepopulate simInstaller settings string, since it's more complex.  
        var simInstallerSettings = []
        var simInstallerStage = []
        if (props.activeProject.generateSimInstaller) {
            simInstallerSettings.push(`generateSimInstaller: true,`) 
            simInstallerSettings.push(`      simInstallerUserFiles: '${props.activeProject.simInstallerUserFiles}',`)
            simInstallerSettings.push(`      simInstallerHmiFiles: '${props.activeProject.simInstallerHmiFiles}'`)
            simInstallerStage.push(`stage('Package') {`)
            simInstallerStage.push(`         steps {`)
            simInstallerStage.push(`            script {`)
            simInstallerStage.push(`               packageSimulatorStage(config: configuration)`)
            simInstallerStage.push(`            }`)
            simInstallerStage.push(`         }`)
            simInstallerStage.push(`      }`)
        } else {
            simInstallerSettings.push(`generateSimInstaller: false`)
            simInstallerStage.push('')
        }

        // Perform a bunch of substitutions in the Jenkinsfile.
        const REGEX = /.*-(\w+)/
        var jenkinsfile = text.replace("$$environment$$", awsExports.aws_user_files_s3_bucket.match(REGEX)[1])
            .replace("$$type$$", props.activeProject.type)
            .replace("$$organizationName$$", props.activeProject.organization.name)
            .replace("$$projectName$$", props.activeProject.alias)
            .replace("$$releaseBranch$$", props.activeProject.branch)
            .replace("$$asConfigsPhysical$$", props.activeProject.physicalConfiguration)
            .replace("$$asConfigsSim$$", props.activeProject.simulationConfiguration)
            .replace("$$jenkinsProjects$$", props.activeProject.organization.name + "/" + props.activeProject.alias)
            .replace("$$asProjectPath$$", props.activeProject.asProject)
            .replace("$$revInfoPath$$", props.activeProject.revInfoPath)
            .replace("$$organization$$", props.activeProject.organization.name)
            .replace("$$bucketName$$", awsExports.aws_user_files_s3_bucket)
            .replace("$$simInstallerSettings$$", simInstallerSettings.join("\n"))
            .replace("$$simInstallerStage$$", simInstallerStage.join("\n"))

        // Download the file via browser.
        var element = document.createElement("a");    
        element.setAttribute("href", "data:application/octet-stream;charset=utf-16le," + encodeURIComponent(jenkinsfile))
        element.setAttribute("download", "Shuv.config")
        element.style.display = "none";
        document.body.appendChild(element);        
        element.click();      
        document.body.removeChild(element);
    }

    async function buildProject() { 
        const buildPipeline = `
            query BuildPipeline {
                buildPipeline(
                    alias: "${props.activeProject.alias}"
                )
            }
        `
        try {
            const response = await API.graphql(graphqlOperation(buildPipeline));
        } catch (err) {
            console.log(err)
        }
    }

    const [popupOpen, setPopupOpen] = useState(false);
    const [deletePipelineButtonText, setDeletePipelineButtonText] = useState('')
    async function deleteProject() {
        // Update button text.
        setDeletePipelineButtonText('Deleting pipeline...')
        // Call a Lambda that deletes all of this pipeline's resources, one by one. 
        const deletePipeline = `
            query DeletePipeline {
                deletePipeline(
                    alias: "${props.activeProject.alias}"
                )
            }
        `
        const response = await API.graphql(graphqlOperation(deletePipeline));
        const parsedResponse = JSON.parse(response.data.deletePipeline);
        console.log(parsedResponse)

        // Now update the org data to reflect the deletion.
        props.updateOrganizationsCb();

        // Wait a couple seconds to ensure the DB is updated.
        // TODO: maybe promisify the updateOrganizationsCb instead of using a timeout?
        setTimeout(() => {
            // Close the popup. 
            setPopupOpen(false);
            // And navigate back to the org's root page (
            history.push("/" + props.organization.name)
        }, 2000);
    }

    async function updateProjectDescription(value) {
        console.log('Updating project description')
        var inputPayload = {
            id: props.activeProject.id,
            description: value
        }
        const updateProject = `
            mutation UpdateProject($input: UpdateProjectInput!) {
                updateProject(input: $input) {
                    id
                }
            }
        `
        var response = await API.graphql(graphqlOperation(updateProject, { input: inputPayload }));
        props.updateOrganizationsCb();
    }

    async function updateProjectTrigger(value) {
        console.log('Updating project trigger')
        var inputPayload = {
            id: props.activeProject.id,
            trigger: value
        }
        const updateProject = `
            mutation UpdateProject($input: UpdateProjectInput!) {
                updateProject(input: $input) {
                    id
                }
            }
        `
        var response = await API.graphql(graphqlOperation(updateProject, { input: inputPayload }));
        props.updateOrganizationsCb();
    }

    const [webhookContent, setWebhookContent] = useState();
    useEffect(() => {
        console.log('Active project', props.activeProject)
        if (props.activeProject.repositoryType === RepositoryType.GITHUB) {
            setWebhookContent(
                <Box p="3" display="flex" flexDirection="column" >
                    <Text>
                        Please add a webhook to your repo. The payload URL should be
                    </Text>
                    <Box mt="3" display="flex" alignItems="center" className="content-box">
                        <Text fontWeight="bold">https://jenkins.loupe.team/github-webhook/</Text>
                        <ButtonInvisible onClick={() => {navigator.clipboard.writeText("https://jenkins.loupe.team/github-webhook/")}}>
                            <StyledOcticon icon={CopyIcon} size={20} />
                        </ButtonInvisible>
                    </Box>
                    <Text mt="3">
                        and the content type should be
                    </Text>
                    <Box mt="3" display="flex" alignItems="center" className="content-box">
                        <Text fontWeight="bold">application/json</Text>
                        <ButtonInvisible onClick={() => {navigator.clipboard.writeText("application/json")}}>
                            <StyledOcticon icon={CopyIcon} size={20} />
                        </ButtonInvisible>
                    </Box>
                    <Text mt="3">
                        To enable triggering from branches or tags, please select the following event triggers:
                    </Text>
                    <Box ml="2" mt="1" display="flex" alignItems="center">
                        <StyledOcticon color="icon.secondary" icon={ArrowRightIcon} size={24}/>
                        <Text ml="1">Pushes (to trigger from pushes to a release branch)</Text>
                    </Box>
                    <Box ml="2" mt="1" display="flex" alignItems="center">
                        <StyledOcticon color="icon.secondary" icon={ArrowRightIcon} size={24}/>
                        <Text ml="1">Branch or tag creation (to trigger from tags)</Text>
                    </Box>                                              
                </Box>
            )
        } else if (props.activeProject.repositoryType === RepositoryType.BITBUCKET) {
            setWebhookContent(
                <Box p="3" display="flex" flexDirection="column" >
                    <Text>
                        Please add a webhook to your repo. The URL should be
                    </Text>
                    <Box mt="3" display="flex" alignItems="center" className="content-box">
                        <Text fontWeight="bold">https://jenkins.loupe.team/bitbucket-scmsource-hook/notify</Text>
                        <ButtonInvisible onClick={() => {navigator.clipboard.writeText("https://jenkins.loupe.team/bitbucket-scmsource-hook/notify")}}>
                            <StyledOcticon icon={CopyIcon} size={20} />
                        </ButtonInvisible>
                    </Box>
                    <Text mt="3">
                        Ensure the 'Active' checkbox is checked.
                    </Text>
                    <Text mt="3">
                        To enable triggering from branches or tags, please select the 'Push' trigger.
                    </Text>                                            
                </Box>
            )
        }
    }, [props.activeProject])

    const [repoCredentialsContent, setRepoCredentialsContent] = useState();
    useEffect(() => {
        if (props.activeProject.repositoryType === RepositoryType.GITHUB) {
            setRepoCredentialsContent(
                <Box display="flex" mt="3" flexDirection="column">
                    <Text  className="f3" fontWeight="bold">Repository Credentials</Text>
                    <Text mb="2" className="f5">Provide a Github username and an associated Personal Access Token that has read access to this repo.</Text>
                    <Box display="flex" flexDirection="row" alignContent="center">
                        <TextInput readOnly={true} className="fill-width" mr="2" placeholder="********************" />  
                        <TextInput readOnly={true} className="fill-width" placeholder="********************" /> 
                    </Box> 
                </Box>
            )         
        } else if (props.activeProject.repositoryType === RepositoryType.BITBUCKET) {
            setRepoCredentialsContent(
                <Box display="flex" mt="3" flexDirection="column">
                    <Text  className="f3" fontWeight="bold">Repository Credentials</Text>
                    <Text mb="2" className="f5">Provide a Bitbucket username and an associated App Password that has read access to this repo.</Text>
                    <Box display="flex" flexDirection="row" alignContent="center">
                        <TextInput readOnly={true} className="fill-width" mr="2" placeholder="********************" />  
                        <TextInput readOnly={true} className="fill-width" placeholder="********************" /> 
                    </Box> 
                </Box>
            ) 
        }
    }, [props.activeProject])

    return (
        <Box>
            <Box display="flex" alignItems="flex-start">
                <SideNav m="4" bordered>
                    <SideNav.Link selected={props.location.pathname === `${url}`}>
                        <Link to={`${url}`}>
                            <Text fontSize="2" color="text.primary">General</Text>
                        </Link>
                    </SideNav.Link>
                    <SideNav.Link selected={props.location.pathname === `${url}/repository`}>
                        <Link to={`${url}/repository`}>
                            <Text fontSize="2" color="text.primary">Repository</Text>
                        </Link>
                    </SideNav.Link>
                    <SideNav.Link selected={props.location.pathname === `${url}/build`}>
                        <Link to={`${url}/build`}>
                            <Text fontSize="2" color="text.primary">Build</Text>
                        </Link>
                    </SideNav.Link>
                </SideNav>
                <Switch>
                    <Route exact path={path}>
                        <Flex mr="2" mt="2" flexDirection="column" alignItems="flex-start" className="project-settings">
                            <Text pt="2" pb="2" className="h2 border-bottom fill-width">Pipeline Information</Text>
                            <Flex mt="1" flexDirection="column" className="fill-width">
                                <Text mb="2" className="f3" fontWeight="bold">Description</Text>
                                <TextInput sx={{bg: 'text.primary', color: 'text.secondary'}} defaultValue={props.activeProject.description} onBlur={(e) => updateProjectDescription(e.target.value)}/>
                            </Flex>
                            <Text pt="5" pb="2" className="h2 border-bottom fill-width">Danger Zone</Text>
                            <Box display="flex" flexDirection="column">
                                <Text mr="1" fontWeight="bold">Delete this pipeline</Text>
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <Text>This operation cannot be reversed. Please be certain!</Text>
                                    <ButtonDanger ml="5" onClick={(e) => {setPopupOpen(true); setDeletePipelineButtonText('I understand, delete this pipeline')}}>Delete this pipeline</ButtonDanger>
                                </Box>                             
                            </Box>
                            <MessagePopup 
                                open={popupOpen} 
                                closePopupCb={(e) => setPopupOpen(false)} 
                                titleJsx={<Text fontWeight="bold">Are you absolutely sure?</Text>}
                                bodyJsx={
                                    <Box display="flex" flexDirection="column">
                                        <Text>This action cannot be undone. The pipeline and all of its associated resources will be permanently delete if you proceed.</Text>
                                        <ButtonDanger mt="3" onClick={(e) => deleteProject()}>{deletePipelineButtonText}</ButtonDanger>
                                    </Box>
                                }
                            />
                        </Flex>
                    </Route>         
                    <Route path={`${path}/repository`}>
                        <Flex mr="2" mt="2" flexDirection="column" alignItems="flex-start" className="project-settings">
                            <Text py="2" className="h2 border-bottom fill-width">Connecting the Repository to Shuv</Text>
                            <Box mt="3" pr="5" className="fill-width">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <Text ml="4">1. Minimum Requirements</Text>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Box p="3" display="flex" flexDirection="column" >
                                                <Text>
                                                    Shuv is supported on the following platforms. Please ensure you meet the minimum requirements:
                                                </Text>
                                                <Box ml="2" mt="1" display="flex" alignItems="center">
                                                    <StyledOcticon color="icon.secondary" icon={ArrowRightIcon} size={24}/>
                                                    <Text ml="1">Automation Studio version 4.2 or later.</Text>
                                                </Box>
                                                <Box ml="2" mt="1" display="flex" alignItems="center">
                                                    <StyledOcticon color="icon.secondary" icon={ArrowRightIcon} size={24}/>
                                                    <Text ml="1">Automation Runtime version A4.73.</Text>
                                                </Box>
                                                <Box ml="2" mt="1" display="flex" alignItems="center">
                                                    <StyledOcticon color="icon.secondary" icon={ArrowRightIcon} size={24}/>
                                                    <Text ml="1">GCC version V4.1.2 or later.</Text>
                                                </Box>
                                            </Box>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                            <Text ml="4">2. Repository Access</Text>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Box p="3" display="flex" flexDirection="column" >
                                                <Text>
                                                    Please ensure the provided Personal Access Token has read access to the repo.
                                                </Text>
                                            </Box>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                            <Text ml="4">3. Webhook Setup</Text>                    
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {webhookContent} 
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                            <Text ml="4">4. Automation Studio Project Files</Text>               
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Box p="3" display="flex" flexDirection="column" >
                                                <Box mt="1" display="flex" alignItems="center">
                                                    <Text mr="1">Please download the following zip file, and import it into the Automation Studio project using the File</Text>
                                                    <StyledOcticon color="icon.secondary" icon={ArrowRightIcon} size={24}/>
                                                    <Text ml="1">Import menu:</Text>
                                                </Box>
                                                <Box as="a" mt="3" display="flex" flexDirection="row" p="3" borderColor="border.primary" borderWidth={1} borderStyle="solid" bg="bg.canvas" color="text.primary" borderRadius={10} href={props.shuvContentSignedUrl}>
                                                    <StyledOcticon icon={FileIcon} size={20} />
                                                    <Text ml="2" fontWeight="bold">Shuv.zip</Text>
                                                </Box>
                                                <Text mt="3">
                                                    This will create a new Shuv folder in the project's Logical View:
                                                </Text>
                                                <Box ml="2" mt="1" display="flex" alignItems="center">
                                                    <StyledOcticon color="icon.secondary" icon={ArrowRightIcon} size={24}/>
                                                    <Text ml="1">Drag the entire folder into your Cpu.sw file's root node to deploy all of its contents.</Text>
                                                </Box>
                                                <Box ml="2" mt="1" display="flex" alignItems="center">
                                                    <StyledOcticon color="icon.secondary" icon={ArrowRightIcon} size={24}/>
                                                    <Text ml="1">Verify that there are no duplicate libraries in your Logical View (compare 'Libraries' folder with 'Shuv/Libraries').</Text>
                                                </Box>
                                                <Box ml="2" mt="1" display="flex" alignItems="center">
                                                    <StyledOcticon color="icon.secondary" icon={ArrowRightIcon} size={24}/>
                                                    <Text ml="1">Ensure that the IotCtrl task is running in a task class with a high tolerance (~ 30 seconds).</Text>
                                                </Box>
                                                <Box ml="2" mt="1" display="flex" alignItems="center">
                                                    <StyledOcticon color="icon.secondary" icon={ArrowRightIcon} size={24}/>
                                                    <Text ml="1">Ensure that the IotCtrl task is running in a task class with a stack size of at least 65535 bytes.</Text>
                                                </Box>
                                                <Box ml="2" mt="1" display="flex" alignItems="center">
                                                    <StyledOcticon color="icon.secondary" icon={ArrowRightIcon} size={24}/>
                                                    <Text ml="1">Copy the contents of the Shuv/Certificates folder into the CertificateStore folder in the Configuration View (there are three files). Note that you will need to copy each of these over individually.</Text>
                                                </Box>
                                                <Text mt="3">
                                                    Lastly, add the following code as a pre-build step in your desired Automation Studio configuration:
                                                </Text>
                                                <Box mt="2" display="flex" alignItems="center" className="content-box">
                                                    <Text fontWeight="bold">
                                                        "$(AS_PROJECT_PATH)\Logical\Shuv\Tasks\Revision\getRevInfo.sh" $(AS_PROJECT_PATH)\Logical\Shuv\Tasks\Revision $(AS_CONFIGURATION) $(AS_USER_NAME)
                                                    </Text>
                                                    <ButtonInvisible onClick={() => {navigator.clipboard.writeText('"$(AS_PROJECT_PATH)\\Logical\\Shuv\\Tasks\\Revision\\getRevInfo.sh" $(AS_PROJECT_PATH)\\Logical\\Shuv\\Tasks\\Revision $(AS_CONFIGURATION) $(AS_USER_NAME)')}}>
                                                        <StyledOcticon icon={CopyIcon} size={20} />
                                                    </ButtonInvisible>
                                                </Box>
                                            </Box> 
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                            <Text ml="4">5. Automation Studio Configuration</Text>               
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Box p="3" display="flex" flexDirection="column" >
                                                <Box mt="1" display="flex" alignItems="center">
                                                    <Text mr="1">Please make the following adjustments to the PLC's configuration menu:</Text>
                                                </Box>
                                                <Box ml="2" mt="3" display="flex" alignItems="center">
                                                    <StyledOcticon color="icon.secondary" icon={ArrowRightIcon} size={24}/>
                                                    <Text ml="1">Configure a minimum user partition size of at least 100MB.</Text>
                                                </Box>
                                                <Box ml="2" mt="3" display="flex" alignItems="center">
                                                    <StyledOcticon color="icon.secondary" icon={ArrowRightIcon} size={24}/>
                                                    <Text ml="1">Configure a file device that points to the root level of the user partition (F:/):</Text>
                                                </Box>
                                                <img className="as-helper-img ml-6 mt-2" src={AsFileDeviceSetup} alt="Ethernet Setup"/>
                                                <Box ml="2" mt="3" display="flex" alignItems="center">
                                                    <StyledOcticon color="icon.secondary" icon={ArrowRightIcon} size={24}/>
                                                    <Text ml="1">Configure the IP address of the PLC's default gateway (this depends on your local network setup):</Text>
                                                </Box>
                                                <img className="as-helper-img ml-6 mt-2" src={AsEthSetup} alt="Ethernet Setup"/>
                                                <Box ml="2" mt="2" display="flex" alignItems="center">
                                                    <StyledOcticon color="icon.secondary" icon={ArrowRightIcon} size={24}/>
                                                    <Text ml="1">Configure a DNS server for domain name resolution:</Text>
                                                </Box>
                                                <img className="as-helper-img ml-6 mt-2" src={AsDnsSetup} alt="DNS Setup"/>
                                                <Box ml="2" mt="2" display="flex" alignItems="center">
                                                    <StyledOcticon color="icon.secondary" icon={ArrowRightIcon} size={24}/>
                                                    <Text ml="1">[OPTIONAL] Configure one (or more) NTP server(s) to synchronize the local clock:</Text>
                                                </Box>
                                                <img className="as-helper-img ml-6 mt-2" src={AsNtpSetup} alt="NTP Setup"/>  
                                            </Box> 
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                            <Text ml="4">6. Automation Script</Text>               
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Box p="3" display="flex" flexDirection="column" >
                                                <Text>
                                                    Please place the following file at the root of your repo:
                                                </Text>
                                                <Box as="button" mt="3" display="flex" flexDirection="row" p="3" borderColor="border.primary" borderWidth={1} borderStyle="solid" bg="bg.canvas" color="text.primary" borderRadius={10} onClick={downloadJenkinsfile}>
                                                    <StyledOcticon icon={FileIcon} size={20} />
                                                    <Text ml="2" fontWeight="bold">Shuv.config</Text>
                                                </Box>
                                            </Box>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                            <Text ml="4">7. Push Changes</Text>               
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Box p="3" display="flex" flexDirection="column" >
                                                <Box ml="2" mt="1" display="flex" alignItems="center">
                                                    <StyledOcticon color="icon.info" icon={RepoPushIcon} size={24}/>
                                                    <Text ml="3">Please commit and push the changes to your remote repo. This will launch the first Shuv build.</Text>
                                                </Box>
                                                <Box ml="2" mt="3" display="flex" alignItems="center">
                                                    <StyledOcticon color="icon.info" icon={TagIcon} size={24}/>
                                                    <Text ml="3">Note that a tag will need to be applied after pushing if the tag build trigger was configured. </Text>
                                                </Box>
                                            </Box> 
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="7">
                                        <Accordion.Header>
                                            <Text ml="4">8. Add Machine(s) and Transfer!</Text>               
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Box p="3" display="flex" flexDirection="column" >
                                                <Box ml="2" mt="1" display="flex" alignItems="center">
                                                    <StyledOcticon color="icon.info" icon={PackageDependentsIcon} size={24}/>
                                                    <Text ml="3">The last step is to transfer the Automation Studio project to your first machine.</Text>
                                                </Box>
                                                <Box ml="2" mt="3" display="flex" alignItems="center">
                                                    <StyledOcticon color="icon.success" icon={SyncIcon} size={24}/>
                                                    <Text ml="3">The machine will register with Shuv automatically when it first connects.</Text>
                                                </Box>
                                                <Box ml="2" mt="3" display="flex" alignItems="center">
                                                    <StyledOcticon color="icon.warning" icon={AlertIcon} size={24}/>
                                                    <Text ml="3">If the connection is not successful, please walk through the troubleshooting suggestions in the next section.</Text>
                                                </Box>                                                
                                            </Box> 
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="8">
                                        <Accordion.Header>
                                            <Text ml="4">9. Troubleshooting</Text>               
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Box p="3" display="flex" flexDirection="column" >
                                                <Text mt="1">If the machine does not connect, please check the following:</Text>
                                                <Box ml="2" mt="1" display="flex" alignItems="center">
                                                    <StyledOcticon color="icon.secondary" icon={ArrowRightIcon} size={24}/>
                                                    <Text ml="1">Open a Watch window on IotCtrl task, and try to ping google.com by setting diag.in.cmd.ping to TRUE.</Text>
                                                </Box>
                                                <Box ml="2" mt="1" display="flex" alignItems="center">
                                                    <StyledOcticon color="icon.secondary" icon={ArrowRightIcon} size={24}/>
                                                    <Text ml="1">Verify that the PLC's clock is set to the correct time.</Text>
                                                </Box>
                                            </Box> 
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Box>                  

                            <Text pt="5" pb="2" className="h2 border-bottom fill-width">Repository Settings</Text>
                            <Flex flexDirection="column" className="fill-width">
                                <Text mb="2" className="f3" fontWeight="bold">Repository URL</Text>
                                <TextInput sx={{bg: 'text.primary', color: 'text.secondary'}} readOnly={true} defaultValue={props.activeProject.repository}/>  
                            </Flex>
                            {repoCredentialsContent}
                            <Text pt="5" pb="2" className="h2 border-bottom fill-width">Resource Paths</Text>
                            <Flex flexDirection="column">
                                <Flex flexDirection="column">
                                    <Text mb="2" className="f3" fontWeight="bold">AS Project Path</Text>
                                    <TextInput sx={{bg: 'text.primary', color: 'text.secondary'}} readOnly={true} defaultValue={props.activeProject.asProject} />
                                </Flex>
                                <Flex mt="3" flexDirection="column">
                                    <Text mb="2" className="f3" fontWeight="bold">Physical Configuration to Build</Text>
                                    <Text mb="2" className="f5">The name of the configuration in the AS project that represents the physical system.</Text>
                                    <TextInput sx={{bg: 'text.primary', color: 'text.secondary'}} readOnly={true} aria-label="project name" name="project name" defaultValue={props.activeProject.physicalConfiguration}/>
                                </Flex>
                                <Flex my="3" flexDirection="column">
                                    <Text mb="2" className="f3" fontWeight="bold">Simulation Configuration to Build</Text>
                                    <Text mb="2" className="f5">The name of the configuration in the AS project that represents the simulation system.</Text>
                                    <TextInput sx={{bg: 'text.primary', color: 'text.secondary'}} readOnly={true} aria-label="project name" name="project name" defaultValue={props.activeProject.simulationConfiguration} />
                                </Flex>

                            </Flex>
                        </Flex>
                    </Route>
                    
                    <Route path={`${path}/build`}>
                        <Flex mr="2" mt="2" flexDirection="column" alignItems="flex-start" className="project-settings">
                            <Flex mt="3" flexDirection="column">
                                <Text pb="2" className="h2 border-bottom fill-width">Triggers</Text>
                                <Text mb="2" className="f3" fontWeight="bold">Build Trigger</Text>
                                <Box>
                                    <Box display="flex" alignItems="center">
                                        <input disabled type="radio" id="trigger-tag" name="trigger" value="tag" onChange={(e) => updateProjectTrigger(e.target.value)} />
                                        <StyledOcticon color="icon.secondary" ml="2" icon={TagIcon} size={20} />
                                        <Box ml="1" display="flex" flexDirection="column" alignItems="flex-start">
                                            <Text color="icon.secondary" fontWeight="bold">Tag</Text>
                                            <Text color="icon.secondary" className="f5">Trigger every time a tag is pushed.</Text>
                                        </Box>   
                                    </Box>
                                    <Box mt="2" display="flex" alignItems="center">
                                        <input type="radio" id="trigger-branch" name="trigger" value="branch" onChange={(e) => updateProjectTrigger(e.target.value)} />
                                        <StyledOcticon ml="2" icon={GitBranchIcon} size={20} />
                                        <Box ml="1" display="flex" flexDirection="column">
                                            <Text fontWeight="bold">Branch</Text>
                                            <Text className="f5">Trigger every time a commit is pushed to the release branch.</Text>
                                        </Box>     
                                    </Box>                    
                                </Box>
                                <Flex mt="3" flexDirection="column">
                                    <Text mb="2" className="f3" fontWeight="bold">Release Branch Name</Text>
                                    <TextInput sx={{bg: 'text.primary', color: 'text.secondary'}} readOnly={true} aria-label="project name" name="project name" defaultValue={props.activeProject.branch} />
                                </Flex>  
                                <Text pt="5" pb="2" className="h2 border-bottom fill-width">Manual Build</Text>
                                <Box display="flex" flexDirection="row">
                                    <Text mr="1">Click</Text>
                                    <PrimerLink as="button" className={props.fontSize} onClick={buildProject}>here</PrimerLink>
                                    <Text ml="1">to manually trigger a build for this pipeline.</Text>
                                </Box>  
                            </Flex>                       
                        </Flex> 
                    </Route>
                </Switch>
            </Box>
        </Box>     
    )
}


export default withRouter(ProjectDetailPage);