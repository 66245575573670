import React, { useState, useEffect, useRef } from 'react';
import '../Styles/App.css';
import '../Styles/Globe.css';
import '../Styles/Bootstrap.scss';
import VersionLabel from './VersionLabel.js';
import ProjectLabel from './ProjectLabel.js';
import LatestBuild from './LatestBuild.js';
import TimeDiff from './TimeDiff.js';
import MessagePopup from './MessagePopup';
import  Amplify from 'aws-amplify';
import { Button, ButtonDanger, Text, Box, Label, CircleOcticon, Flex, StyledOcticon, BorderBox, Flash, Dropdown } from '@primer/components';
import { RocketIcon, ContainerIcon, CheckCircleFillIcon, PersonIcon, ClockIcon, PlugIcon, AlertIcon, BroadcastIcon, CodeSquareIcon, UnverifiedIcon, VerifiedIcon, ArrowRightIcon, CheckIcon, ArrowDownIcon } from '@primer/octicons-react';
import { Link } from 'react-router-dom';
import { API, graphqlOperation, Storage } from 'aws-amplify';

function Machine(props){

    const [deploymentStatus, setDeploymentStatus] = useState();
    const [online, setOnline] = useState();
    const [latestBuiltVersion, setLatestBuiltVersion] = useState();
    const [latestBuiltSafetyVersion, setLatestBuiltSafetyVersion] = useState();
    const [machineVersion, setMachineVersion] = useState();
    const [machineSafetyVersion, setMachineSafetyVersion] = useState();
    const [updateTime, setUpdateTime] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [errorId, setErrorId] = useState();
    const [description, setDescription] = useState();

    useEffect(() => {
        fetchMachineInfo();
        subscribeToMachineUpdates();
        fetchAvailableVersions();
    },[])

    async function fetchMachineInfo() {
        const getMachineInfo = `
            query MyQuery {
                getMachine(id: "` + props.machine.id + `") {
                    deploymentStatus
                    latestBuiltVersion
                    latestBuiltSafetyVersion
                    online
                    errorMessage
                    errorId
                    machineVersion
                    machineSafetyVersion
                    updateTime
                    description
                }
            }
        `
        const response = await API.graphql(graphqlOperation(getMachineInfo));
        console.log('Machine info', response)
        setOnline(response.data.getMachine.online);
        setDeploymentStatus(response.data.getMachine.deploymentStatus);
        setErrorMessage(response.data.getMachine.errorMessage);
        setErrorId(response.data.getMachine.errorId);
        setLatestBuiltVersion(response.data.getMachine.latestBuiltVersion);
        setMachineVersion(response.data.getMachine.machineVersion);  
        setLatestBuiltSafetyVersion(response.data.getMachine.latestBuiltSafetyVersion);
        setMachineSafetyVersion(response.data.getMachine.machineSafetyVersion);
        setUpdateTime(response.data.getMachine.updateTime);
        setDescription(response.data.getMachine.description);  
        console.log('Latest built version', response.data) 
    }
    
    function onCustomMachineUpdate(value) {    
        console.log('Received a machine update', value)  
        setOnline(value.data.onUpdateMachineByID.online);
        setDeploymentStatus(value.data.onUpdateMachineByID.deploymentStatus);
        setLatestBuiltVersion(value.data.onUpdateMachineByID.latestBuiltVersion);
        setLatestBuiltSafetyVersion(value.data.onUpdateMachineByID.latestBuiltSafetyVersion);
        setMachineVersion(value.data.onUpdateMachineByID.machineVersion);
        setMachineSafetyVersion(value.data.onUpdateMachineByID.machineSafetyVersion);
        setUpdateTime(value.data.onUpdateMachineByID.updateTime);
        setErrorMessage(value.data.onUpdateMachineByID.errorMessage);
        setErrorId(value.data.onUpdateMachineByID.errorId);
        setDescription(value.data.onUpdateMachineByID.description);
    }

    async function subscribeToMachineUpdates() {
        const onMachineUpdateByID =  `
            subscription UpdateMachineByID {
                onUpdateMachineByID(id: "` + props.machine.id + `") {
                    latestBuiltVersion
                    machineVersion
                    latestBuiltSafetyVersion
                    machineSafetyVersion
                    name
                    online
                    deploymentStatus
                    errorMessage
                    errorId
                    longitude
                    latitude
                    updateTime
                    description
                }
            }
        `
        const subscription = await API.graphql(
            graphqlOperation(onMachineUpdateByID)
        ).subscribe({
            next: ({ provider, value }) => onCustomMachineUpdate(value),
            error: error => console.warn(error)
        })
    }

    // Retrieve the available versions from S3. 
    const [versions, setVersions] = useState([]);
    async function fetchAvailableVersions() {
        Storage.configure({
            level: 'public', 
            customPrefix: {
                public: `userData/${props.organization.name}/${props.machine.project.alias}`
            }
        });
        Storage.list('')
            .then(result => {
                let files = []
                result.forEach(res => {
                    const splitKey = res.key.split('/')
                    const file = {
                        name: splitKey[1],
                        lastModified: res.lastModified
                    }
                    // Add the file to working list if it's not already in there.
                    if((!files.some(item => item.name === splitKey[1])) && (splitKey[1] !== 'artifacts')) {
                        files.push(file)
                    }
                })
                files.sort(function(a,b) {
                    return new Date(b.lastModified) - new Date(a.lastModified);
                })
                setVersions(files)
            })
            .catch(err => {
                console.log('Error: ', err)
            });
    }

    // Update the desired sw version for this machine's shadow (via a Lambda function)
    async function updateDesiredSwVersion() {
        setPopupOpen(false);
        setLatestBuiltVersion(proposedSwVersion);
        const updateShadow = `
            query UpdateShadow {
                updateShadow(
                    machineName: "${props.machine.name}",
                    swVersion: "${proposedSwVersion}",
                    safetySwVersion: ""
                )
            }
        `
        const response = await API.graphql(graphqlOperation(updateShadow));
        const parsedResponse = JSON.parse(response.data.updateShadow);
        console.log(parsedResponse)
    }
    // Handle the confirmation popup and proposed new software version. 
    const [popupOpen, setPopupOpen] = useState(false);
    const [proposedSwVersion, setProposedSwVersion] = useState();
    function proposeSwVersion(version) {
        closeDropdown();
        setProposedSwVersion(version);
        setPopupOpen(true);
    }

    function updateOnlineStatus(param) {
        if (param) {
            return <CircleOcticon color="icon.success" bg="text.inverse" icon={BroadcastIcon} />;
        } else {
            return <CircleOcticon bg="text.inverse" icon={ClockIcon} />;
        }
    }

    function updateDeploymentStatusFlashVariant(param) {
        switch(param) {
            case 'Up to date':
                return 'success';
            case 'Waiting for operator':
                return 'default';
            case 'Updating - Getting installer':
                return 'warning';
            case 'Updating - Verifying installer':
                return 'warning';
            case 'Updating - Rebooting':
                return 'warning';
            case 'Safety - Waiting for operator':
                return 'default';
            case 'Safety - Validating application':
                return 'warning';
            case 'Safety - Downloading application':
                return 'warning';
            case 'Safety - Acknowledging application':
                return 'warning';
            case 'Safety - Rebooting':
                return 'warning';
            case 'Error':
                return 'danger';
            default:
                return 'default';
        }
    }

    function updateDeploymentStatus(param) {
        const jsx = [];
        switch(param) {
            case 'Up to date':
                jsx.push(<Box>
                            <StyledOcticon color="icon.success" icon={CheckCircleFillIcon} size="32" />
                            <Text mx="1" className="f3" color="text.primary">Successfully deployed</Text>
                            <VersionLabel version={machineVersion} repo={props.machine.project.repository} />
                            <TimeDiff time={updateTime} inProgress={true} />
                        </Box>)
                break;
            case 'Waiting for operator':
                jsx.push(<Box>
                            <StyledOcticon color="icon.info" icon={PersonIcon} size="32"/>
                            <Text mx="1" className="f3" color="text.primary">Deployment</Text>
                            <VersionLabel version={latestBuiltVersion} repo={props.machine.project.repository} />
                            <Text mx="1" className="f3" color="text.primary">is ready, waiting for operator confirmation</Text>
                         </Box>)
                break;
            case 'Updating - Getting installer':
                jsx.push(<Box>
                            <StyledOcticon className="icn-wiggle" color="icon.warning" icon={RocketIcon} size="32" />
                            <Text ml="1" mr="1" className="f3" color="text.primary">Deploying</Text>
                            <VersionLabel version={latestBuiltVersion} repo={props.machine.project.repository} />
                            <Text ml="1" className="f3" color="text.primary">to the device:</Text>
                            <br/>
                            <StyledOcticon ml="8" icon={ArrowRightIcon}  size="24" />
                            <Text ml="1" className="f3" color="text.primary">Retrieving the installer</Text>
                        </Box>)
                break;
            case 'Updating - Verifying installer':
                jsx.push(<Box>
                            <StyledOcticon className="icn-wiggle" color="icon.warning" icon={RocketIcon} size="32" />
                            <Text ml="1" mr="1" className="f3" color="text.primary">Deploying</Text>
                            <VersionLabel version={latestBuiltVersion} repo={props.machine.project.repository} />
                            <Text ml="1" className="f3" color="text.primary">to the device:</Text>
                            <br/>
                            <StyledOcticon ml="8" icon={CheckIcon}  size="24" />
                            <Text ml="1" className="f3" color="text.primary">Retrieving the installer</Text>
                            <br/>
                            <StyledOcticon ml="8" icon={ArrowRightIcon}  size="24" />
                            <Text ml="1" className="f3" color="text.primary">Verifying the installer</Text>
                        </Box>)
                break;
            case 'Updating - Rebooting':
                jsx.push(<Box>
                            <StyledOcticon className="anim-pulse" color="icon.warning" icon={PlugIcon} size="32" />
                            <Text ml="1" mr="1" className="f3" color="text.primary">Deploying</Text>
                            <VersionLabel version={latestBuiltVersion} repo={props.machine.project.repository} />
                            <Text ml="1" className="f3" color="text.primary">to the device:</Text>
                            <br/>
                            <StyledOcticon ml="8" icon={CheckIcon}  size="24" />
                            <Text ml="1" className="f3" color="text.primary">Retrieving the installer</Text>
                            <br/>
                            <StyledOcticon ml="8" icon={CheckIcon}  size="24" />
                            <Text ml="1" className="f3" color="text.primary">Verifying the installer</Text>
                            <br/>
                            <StyledOcticon ml="8" icon={ArrowRightIcon}  size="24" />
                            <Text ml="1" className="f3" color="text.primary">Rebooting to complete the deployment</Text>
                        </Box>)
                break;
            case 'Safety - Waiting for operator':
                jsx.push(<Box>
                            <StyledOcticon color="icon.info" icon={PersonIcon} size="32"/>
                            <Text ml="1" mr="1" className="f3" color="text.primary">Safety deployment</Text>
                            <VersionLabel type="safety" version={latestBuiltSafetyVersion}/>
                            <Text ml="1" className="f3" color="text.primary">is ready, waiting for operator confirmation</Text>
                        </Box>)           
                break;
            case 'Safety - Validating application':
                jsx.push(<Box>
                            <StyledOcticon className="icn-wiggle" color="icon.warning" icon={RocketIcon} size="32" />
                            <Text ml="1" mr="1" className="f3" color="text.primary">Deploying</Text>
                            <VersionLabel type="safety" version={latestBuiltSafetyVersion}/>
                            <Text ml="1" className="f3" color="text.primary">to the safety controller:</Text>
                            <br/>
                            <StyledOcticon ml="8" icon={UnverifiedIcon}  size="24" />
                            <Text mr="1" className="f3" color="text.primary">Validating safe application</Text>
                        </Box>)           
                break;
            case 'Safety - Downloading application':
                jsx.push(<Box>
                            <StyledOcticon className="icn-wiggle" color="icon.warning" icon={RocketIcon} size="32" />
                            <Text ml="1" mr="1" className="f3" color="text.primary">Deploying</Text>
                            <VersionLabel type="safety" version={latestBuiltSafetyVersion}/>
                            <Text ml="1" className="f3" color="text.primary">to the safety controller:</Text>
                            <br/>
                            <StyledOcticon ml="8" icon={CheckIcon}  size="24" />
                            <Text mr="1" className="f3" color="text.primary">Validating safe application</Text>
                            <br/>
                            <StyledOcticon ml="8" icon={ArrowRightIcon}  size="24" />
                            <Text mr="1" className="f3" color="text.primary">Downloading safe application</Text>
                        </Box>) 
                break;
            case 'Safety - Acknowledging application':
                jsx.push(<Box>
                            <StyledOcticon className="icn-wiggle" color="icon.warning" icon={RocketIcon} size="32" />
                            <Text ml="1" mr="1" className="f3" color="text.primary">Deploying</Text>
                            <VersionLabel type="safety" version={latestBuiltSafetyVersion}/>
                            <Text ml="1" className="f3" color="text.primary">to the safety controller:</Text>
                            <br/>
                            <StyledOcticon ml="8" icon={CheckIcon}  size="24" />
                            <Text mr="1" className="f3" color="text.primary">Validating safe application</Text>
                            <br/>
                            <StyledOcticon ml="8" icon={CheckIcon}  size="24" />
                            <Text mr="1" className="f3" color="text.primary">Downloading safe application</Text>
                            <br/>
                            <StyledOcticon ml="8" icon={ArrowRightIcon}  size="24" />
                            <Text mr="1" className="f3" color="text.primary">Confirming safe application</Text>
                        </Box>) 
                break;
            case 'Safety - Rebooting':
                jsx.push(<Box>
                            <StyledOcticon className="anim-pulse" color="icon.warning" icon={PlugIcon} size="32" />
                            <Text ml="1" mr="1" className="f3" color="text.primary">Deploying</Text>
                            <VersionLabel type="safety" version={latestBuiltSafetyVersion}/>
                            <Text ml="1" className="f3" color="text.primary">to the safety controller:</Text>
                            <br/>
                            <StyledOcticon ml="8" icon={CheckIcon}  size="24" />
                            <Text mr="1" className="f3" color="text.primary">Validating safe application</Text>
                            <br/>
                            <StyledOcticon ml="8" icon={CheckIcon}  size="24" />
                            <Text mr="1" className="f3" color="text.primary">Downloading safe application</Text>
                            <br/>
                            <StyledOcticon ml="8" icon={CheckIcon}  size="24" />
                            <Text mr="1" className="f3" color="text.primary">Confirming safe application</Text>
                            <br/>
                            <StyledOcticon ml="8" icon={ArrowRightIcon}  size="24" />
                            <Text mr="1" className="f3" color="text.primary">Rebooting safety controller</Text>
                        </Box>)    
                break;
            case 'Error':
                jsx.push(<Box>
                            <StyledOcticon color="icon.danger" icon={AlertIcon} size="32" />
                            <Text ml="1" mr="1" color="text.primary" className="f3">Error #{errorId} ({errorMessage})</Text>
                        </Box>)
                break;
            default:
                jsx.push(<Text ml="1" mr="1" className="f3" color="text.primary">No deployment information available</Text>)
                break;
        }
        return jsx
    }

    // Get a ref to a text field next to the dropdown, and generate click event on this field when the dropdown should be closed. 
    const desiredVersionRef = useRef()
    function closeDropdown() {
        if (desiredVersionRef.current !== undefined){
            desiredVersionRef.current.click();
        }
    }

    return (
        <Box>
        {
            ((typeof online !== 'undefined') && (typeof deploymentStatus !== 'undefined') && (typeof latestBuiltVersion !== 'undefined') && (typeof machineVersion !== 'undefined')) ? (
                <Box>
                {
                    (props.mode === 'header') ? (
                        <Text className="f3" ml="4" mt="0">{description}</Text>   
                    ) : (
                        <div/>
                    )
                }
                {
                    (props.mode === 'overview') ? (
                        <Box className="border-bottom hello">
                            <Flex alignItems="center" mt="3" justifyItems="center" flexWrap="wrap">
                                <Flex mt="2">
                                    {
                                        updateOnlineStatus(online)
                                    }             
                                    <Link className="h2 ml-2 mr-3 link" to={'/' + props.organization.name + '/machines/' + props.machine.name}>{props.machine.name}</Link>
                                </Flex>
                                <Flex mt="2">
                                    <VersionLabel 
                                        version={machineVersion} 
                                        repo={props.machine.project.repository} 
                                        status={((machineVersion === latestBuiltVersion) || (latestBuiltVersion === '')) ? ("up to date") : ("outdated")}
                                    /> 
                                    {
                                        (latestBuiltSafetyVersion !== '') ? (
                                            <VersionLabel 
                                                type="safety"
                                                version={machineSafetyVersion}  
                                                status={((machineSafetyVersion === latestBuiltSafetyVersion) || (latestBuiltSafetyVersion === '')) ? ("up to date") : ("outdated")}
                                            /> 
                                        ) : (
                                            <div />
                                        )
                                    }
                                    <div className="mx-2"/>
                                    <ProjectLabel projectName={props.machine.project.alias} orgName={props.organization.name} />    
                                </Flex>       
                            </Flex>
                            <Flex mt="2">
                                <Text className="f3">{description}</Text>
                            </Flex>       
                            <Flash mt="2" mb="5" variant={updateDeploymentStatusFlashVariant(deploymentStatus)}>
                            {
                                updateDeploymentStatus(deploymentStatus)
                            }        
                            </Flash>                                
                        </Box>
                    ) : (
                        <Box/>
                    )
                }
                {
                    (props.mode === 'detail') ? (
                        <div>
                            <MessagePopup 
                                open={popupOpen} 
                                closePopupCb={(e) => setPopupOpen(false)} 
                                titleJsx={
                                    (deploymentStatus === 'Up to date' || deploymentStatus === 'Waitng for operator') ? (
                                        <Text fontWeight="bold">Please Confirm</Text>
                                    ) : (
                                        <Text fontWeight="bold">Operation Not Allowed</Text>
                                    )
                                }
                                bodyJsx={
                                    (deploymentStatus === 'Up to date' || deploymentStatus === 'Waiting for operator' || deploymentStatus === 'Error') ? (
                                        (proposedSwVersion === machineVersion) ? (
                                            <Box display="flex" flexDirection="column">
                                                <Text>Are you sure you want to withdraw the <VersionLabel version={latestBuiltVersion} /> update from {props.machine.name}?</Text>
                                                <br />
                                                <Text>If you proceed, the machine will no longer indicate that an update is available for download.</Text>
                                                <Box display="flex" flexDirection="row" mt="4" justifyContent="center">
                                                    <Button mr="5" onClick={(e) => updateDesiredSwVersion()}>Yes, withdraw it</Button>
                                                    <Button onClick={() => setPopupOpen(false)}>No</Button>
                                                </Box>
                                            </Box>
                                        ) : (  
                                            <Box display="flex" flexDirection="column">                             
                                                <Text>Are you sure you want to deliver <VersionLabel version={proposedSwVersion} /> to {props.machine.name}?</Text>
                                                <br />
                                                <Text>This action will prompt the machine operator to confirm the update.</Text>
                                                <Box display="flex" flexDirection="row" mt="4" justifyContent="center">
                                                    <ButtonDanger mr="5" onClick={(e) => updateDesiredSwVersion()}>Yes, deliver it</ButtonDanger>
                                                    <Button onClick={() => setPopupOpen(false)}>No</Button>
                                                </Box>
                                            </Box>
                                        )      
                                    ) : (
                                        <Text>The software version to deploy cannot be changed while an update is in progress.</Text>
                                    )                    
                                }
                            />
                            <BorderBox mt="5" mb="2">                           
                                <Box p="3" className="border-bottom" display="flex" alignContent="center">
                                    {updateOnlineStatus(online)}
                                    <Text ml="2" textAlign="left" className="h3" mr="4">The device is {online ? "online" : "offline"}</Text>
                                </Box>                                                            
                                {
                                    ((machineVersion === latestBuiltVersion) || (latestBuiltVersion === '')) ? (
                                        <Flex p="3" alignItems="center">
                                            <Text className="h3" textAlign="left" mr="4"><b>The software on this machine is up to date.</b></Text>
                                        </Flex>
                                    ) : (
                                        <Flex p="3" alignItems="center">
                                            <Text textAlign="left" mr="4"><b>There is a new software version available:</b></Text>
                                        </Flex>
                                    )
                                } 
                                <Flex px="3" alignItems="center"> 
                                    <Text ref={desiredVersionRef} textAlign="left" className="status-text" mr="4">Desired software version:</Text>    
                                    <Dropdown>
                                        <Dropdown.Button>
                                            <VersionLabel version={latestBuiltVersion} repo={props.machine.project.repository} />
                                        </Dropdown.Button>
                                        <Dropdown.Menu direction='sw' >
                                        {             
                                            versions.map((item, index) => (
                                                <Dropdown.Item pl={3} key={index} onClick={(event) => proposeSwVersion(item.name)}>                                                  
                                                    <VersionLabel version={item.name} />  
                                                </Dropdown.Item>              
                                            ))
                                        }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Flex> 
                                <Flex className="border-bottom"  p="3" alignItems="center">
                                    <Text textAlign="left" className="status-text" mr="4">Installed Software Version (<i>on the machine</i>):</Text>
                                    <Flex alignItems="center" >     
                                        <VersionLabel 
                                            version={machineVersion} 
                                            repo={props.machine.project.repository} 
                                            status={((machineVersion === latestBuiltVersion) || (latestBuiltVersion === '')) ? ("up to date") : ("outdated")}
                                        />     
                                    </Flex> 
                                </Flex>                        
                                <Flex px="3" pt="3">                         
                                    <Text textAlign="left" className="h3" mr="4">Pipeline Status for</Text>                       
                                    <ProjectLabel projectName={props.machine.project.alias} orgName={props.organization.name} />  
                                    <Text textAlign="left" className="h3" mr="4">:</Text>  
                                </Flex>
                                <Flex className="border-bottom" px="3" pb="3" alignItems="center">                              
                                    <LatestBuild project={props.machine.project} fontSize="f3"/>  
                                </Flex>  
                                <Flex px="3" pt="3" alignItems="center">
                                    <Text textAlign="left" className="h3" mr="4">Deployment status:</Text>         
                                </Flex>  
                                <Flex className="border-bottom" px="3" pb="3" alignItems="center">                 
                                    <Flash variant={updateDeploymentStatusFlashVariant(deploymentStatus)}>
                                        <Flex alignItems="center" flexWrap="wrap" >  
                                        {
                                            updateDeploymentStatus(deploymentStatus)
                                        }                             
                                        </Flex> 
                                    </Flash>
                                </Flex> 

                            </BorderBox>                                                  
                        </div>
                    ) : (
                        <div/>
                    )
                }
                </Box>
            ) : (
                <div/>
            )
        }
        </Box>
    )
}

export default Machine;