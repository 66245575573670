import '../Styles/App.css';
import '../Styles/Primer.scss';
import Machine from '../Components/Machine.js';
import Project from '../Components/Project.js';
import MachineGlobe from '../Components/MachineGlobe.js';
import '../Styles/Bootstrap.scss';
import { Button, Breadcrumb, Pagehead, Text, Flex, Header, StyledOcticon, Avatar, UnderlineNav, Dropdown, TextInput, Link as PrimerLink, CounterLabel, ButtonPrimary, FilteredSearch, Box } from '@primer/components';
import { LinkIcon, LocationIcon, SyncIcon, SearchIcon, ContainerIcon, PlugIcon, PersonIcon, PaperAirplaneIcon } from '@primer/octicons-react';
import { useRouteMatch, Link, Switch, Route, withRouter, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Analytics } from 'aws-amplify';

function OrganizationPage(props){

    let { path, url } = useRouteMatch();

    useEffect(() => {
        console.log('Active organization name changed to ' + props.organization.name) 
        const response = Analytics.record({ 
            name: 'pageChange',
            attributes: {
                user: props.user.username,
                page: 'Organization Page',
                organization: props.organization.name
            }
        })
    }, [props.organization.name])

    let { activeOrg } = useParams();
    useEffect(() => {
        props.updateActiveOrganizationIndexCb(activeOrg);
    },[activeOrg])

    return (
        <div>
            <Pagehead py="0" my="2">
                <Header bg="bg.canvas">
                    <Header.Item>
                        <Flex flexDirection="row">
                            <Avatar mr={2} square size={75} src={props.organization.avatar} alt="Avatar"/>
                            <Flex flexDirection="column">
                                <Text className="h2" color="text.primary">{props.organization.name}</Text> 
                                {
                                    (props.organization.name === props.user.username) ? (
                                        <Box display="flex">
                                            <StyledOcticon mr="1" icon={PersonIcon} size={20} color="icon.primary" />
                                            <Text color="text.primary" mr="3" className="f4">Personal organization</Text>
                                        </Box>
                                    ) : (
                                        <Box display="flex" mt="0" flexDirection="row" alignItems="center" flexWrap="wrap">
                                            <Box display="flex">
                                                <StyledOcticon mr="1" icon={LocationIcon} size={20} color="icon.primary" />
                                                <Text mr="3" className="f4">{props.organization.location}</Text>
                                            </Box>
                                            <Box display="flex">
                                                <StyledOcticon mr="1" icon={LinkIcon} size={20} color="icon.primary" />                                        
                                                <PrimerLink className="f4" color="text.primary" href={props.organization.website}>
                                                    {props.organization.website}
                                                </PrimerLink>
                                            </Box>
                                        </Box>    
                                    )
                                }                                             
                            </Flex>
                        </Flex>                                         
                    </Header.Item>
                    <Header.Item full></Header.Item>
                </Header>

                <UnderlineNav aria-label="Main">
                    <UnderlineNav.Link sx={{borderBottomWidth: 5}} ml="3" selected={props.location.pathname === `${url}`}>
                        <Flex flexDirection="row" alignItems="center">
                            <StyledOcticon mr="2" icon={PaperAirplaneIcon} size={20} color="icon.secondary" />  
                            <Link to={`${url}`}>
                                <Text fontSize="3" color="text.primary">Pipelines</Text>
                            </Link>
                            <CounterLabel ml={2}>{props.projects.length}</CounterLabel>
                        </Flex>
                    </UnderlineNav.Link>
                    <UnderlineNav.Link sx={{borderBottomWidth: 5}} selected={props.location.pathname === `${url}/machines`}>  
                        <Flex flexDirection="row" alignItems="center">
                            <StyledOcticon mr="2" icon={ContainerIcon} size={20} color="icon.secondary" />
                            <Link to={`${url}/machines`}>
                                <Text fontSize="3" color="text.primary">Machines</Text>
                            </Link>
                            <CounterLabel ml={2}>{props.machines.length}</CounterLabel>
                        </Flex>
                    </UnderlineNav.Link>
                </UnderlineNav>

            </Pagehead>
            
            <div>

                <Switch>
                    <Route path={`${path}/machines`}>
                        <Flex flexDirection="row" flexWrap="wrap" justifyContent="center">
                            <Flex className="machine-list" flexDirection="column" alignItems="center">
                            {
                                (props.machines.length > 0) ? (                           
                                    <Box display="flex" flexDirection="column">
                                        {
                                            props.machines.map((machine, index) => (
                                                <Machine
                                                    key={index}
                                                    organization={props.organization}
                                                    machine={machine}
                                                    mode='overview'
                                                />
                                            ))
                                        }
                                        <MachineGlobe machines={props.machines} size={400} />                         
                                    </Box>
                                ) : (
                                    <Box mt="5" p="5" display="flex" flexDirection="column" alignItems="center">
                                        <StyledOcticon icon={PlugIcon} size={35} />
                                        <Text as="h3" textAlign="center" mb="1">This organization doesn't have any machines associated with it.</Text>
                                        <Box display="flex"> 
                                            <Text mr="1" as="p">You can visit {props.organization.name}'s </Text>
                                            <Link className="link" to={"/" + props.organization.name}>
                                                pipelines
                                            </Link>
                                            <Text ml="1" as="p">page to get started.</Text>
                                        </Box>
                                    </Box>
                                )
                            }
                            </Flex> 
                        </Flex> 
                    </Route>          
                    <Route exact path={path}>
                        <Flex flexDirection="row" justifyContent="center">
                            <Flex className="project-list" flexDirection="column" alignItems="center">
                                <Flex mt="2" className="project-details" flexDirection="row" justifyContent="space-between">
                                    <TextInput variant="large" icon={SearchIcon} aria-label="project" name="project" placeholder="Find pipeline" />
                                    <ButtonPrimary as={Link} to={'/' + props.organization.name + '/newpipeline/'} variant="large">
                                        <Box display="flex" flexDirection="row" alignItems="center">
                                            <StyledOcticon color="icon.white" icon={PaperAirplaneIcon} size={20} />
                                            <Text ml="2" color="text.white">New Pipeline</Text>
                                        </Box>                           
                                    </ButtonPrimary>
                                </Flex>
                                {
                                    (props.projects.length > 0) ? (                                   
                                        props.projects.map((project, index) => (
                                            <Project
                                                key={index}
                                                organization={props.organization}
                                                project={project}
                                                mode="overview"
                                            />
                                        ))      
                                    ) : (
                                        <Box mt="5" p="5" display="flex" flexDirection="column" alignItems="center">
                                            <StyledOcticon icon={ContainerIcon} size={35} />
                                            <Text as="h3" textAlign="center" mb="1">This organization doesn't have any pipelines associated with it.</Text>
                                            <Box display="flex"> 
                                                <Text mr="1" as="p">You can create a new pipeline</Text>
                                                <Link className="link" to={"/" + props.organization.name + "/newpipeline"}>
                                                    here
                                                </Link>
                                                <Text as="p">.</Text>
                                            </Box>
                                        </Box>
                                    )
                                }
                                
                                
                            </Flex>  
                        </Flex>
                    </Route>          
                    <Route path="/:any">
                        <div/>
                    </Route>
                </Switch>
   
            </div>
                             
        </div>
    )
}

export default withRouter(OrganizationPage);