import '../Styles/App.css';
import '../Styles/Primer.scss';
import '../Styles/Bootstrap.scss';
import { Auth } from 'aws-amplify';
import ShuvLogo from '../Media/shuv-logo.png';
import { Header, Avatar, Dropdown, Text, Box, StyledOcticon } from '@primer/components';
import { CheckIcon } from '@primer/octicons-react';
import { Link, useHistory } from 'react-router-dom';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import { useRef } from 'react';

function TopBar(props){

    const history = useHistory();

    async function signOut() {
        console.log('Signing out')
        try {
            await Auth.signOut();
        } catch (error) {
            console.log('Error signing out: ', error);
        }
    }

    // Get a ref to a static element that we can use to close the dropdown. 
    const divRef = useRef();
    function closeDropdowns() {
        divRef.current.click();
    }

    function updateActiveOrg(event, index, history) {  
        props.updateActiveOrganizationPathCb(event, index, history);
        closeDropdowns();
    }

    return (
        <div ref={divRef}>
            <Header>
                <Header.Item>
                    <Header.Link fontSize={2}>
                        <Link to="/">
                            <img className="logo" src={ShuvLogo} alt="Shuv" height="50"/>
                        </Link>          
                    </Header.Link>
                </Header.Item>
                {
                    (props.organizations[0]) ? (
                        <Header.Item>
                            <Dropdown>
                                <Dropdown.Button>
                                    <Avatar mr={2} square size={24} src={props.organizations[props.activeOrganizationIndex].avatar} />
                                    <Text>{props.organizations[props.activeOrganizationIndex].name}</Text>
                                </Dropdown.Button>
                                <Dropdown.Menu direction="se" id="context-switcher-menu">
                                    <Dropdown.Item>
                                        <Text fontWeight="bold">
                                            Switch app context:
                                        </Text>               
                                    </Dropdown.Item>
                                    {             
                                        props.organizations.map((organization, index) => (
                                            <Dropdown.Item pl={3} className="dropdown-border" key={index} onClick={(event) => updateActiveOrg(event, index, history)}>                                                  
                                                <Box display="flex" flexDirection="row" alignItems="center">
                                                    <StyledOcticon color={(props.activeOrganizationIndex === index) ? ("icon.primary") : ("transparent")} icon={CheckIcon} size={18} />
                                                    <Avatar mx={2} square size={24} src={organization.avatar} />
                                                    <Text>{organization.name}</Text>
                                                </Box>                
                                            </Dropdown.Item>              
                                        ))
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </Header.Item>
                    ) : (
                        <div />
                    )
                }
                <Header.Item full></Header.Item>
                <Header.Item mr={0}>
                {
                    ((props.userPreferences) && (props.organizations[0])) ? (
                        <Dropdown>
                            <summary>
                                <Avatar
                                    src={props.userPreferences.avatar}
                                    size={20}
                                    circle
                                    alt="@octocat"
                                />
                                <Dropdown.Caret/>
                            </summary>
                            <Dropdown.Menu direction='sw' onClick={closeDropdowns}>
                                <Dropdown.Item>
                                    Signed in as
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <b>{props.user.username}</b>
                                </Dropdown.Item>
                                <Dropdown.Item className='dropdown-border'>
                                    <Link as={Text} to={"/" + props.organizations[props.activeOrganizationIndex].name}>
                                        Your organization
                                    </Link>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <Link as={Text} to={"/" + props.organizations[props.activeOrganizationIndex].name + "/machines"}>
                                        Your machines
                                    </Link>
                                </Dropdown.Item>
                                <Dropdown.Item className='dropdown-border'>Help</Dropdown.Item>
                                <Dropdown.Item>
                                    <Link as={Text} to={"/settings"}>
                                        Settings
                                    </Link>
                                </Dropdown.Item>
                                <Dropdown.Item className="dropdown-border">
                                    <Box id="sign-out-container">
                                        <Text id="sign-out-text">Sign Out</Text>
                                        <AmplifySignOut id="sign-out-button" buttonText=""></AmplifySignOut>
                                    </Box>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    ) : (
                        <div />
                    )
                }
                </Header.Item>
            </Header>              
        </div>
    )
}

export default TopBar;