import { useEffect, useState } from 'react';
import '../Styles/Primer.scss';
import '../Styles/Bootstrap.scss';
import '../Styles/App.css';
import { AmplifySignIn, AmplifyAuthenticator, AmplifySignUp, AmplifyConfirmSignUp } from '@aws-amplify/ui-react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { AuthState } from '@aws-amplify/ui-components';
import { Box, ButtonPrimary, StyledOcticon, Text, TextInput, Link as PrimerLink, ButtonInvisible, Button } from '@primer/components';
import ShuvLogo from '../Media/shuv-logo.png';
import { useHistory, Link } from 'react-router-dom';
import { ArrowLeftIcon, ArrowRightIcon } from '@primer/octicons-react';
import $ from "jquery";

function SignUpPage(props){

    let history = useHistory();

    const [signUpStage, setSignUpStage] = useState('sign up');
    const [email, setEmail] = useState('');
    const [emailMessage, setEmailMessage] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberMessage, setPhoneNumberMessage] = useState('');
    const [fullName, setFullName] = useState('');
    const [fullNameMessage, setFullNameMessage] = useState('');
    const [info, setInfo] = useState('');
    const [buttonText, setButtonText] = useState('Sign up to be notified');
    const [verificationCode, setVerificationCode] = useState(0);
    const [verificationCodeMessage, setVerificationCodeMessage] = useState('')
    const [newsletterSignup, setNewsletterSignup] = useState(true);

    useEffect(() => {
        $("#newsletter-checkbox").trigger("click")
        setNewsletterSignup(true);
    }, [])

    function processInfo(data) {
        const splitInfo = data.split(/\r?\n/)
        console.log(splitInfo)
        const joinedInfo = splitInfo.join(' ')
        setInfo(joinedInfo)
        console.log(joinedInfo)
    }

    function returnToSignUp() {
        setFullName('')
        setEmail('')
        setPhoneNumber('')
        setInfo('')
        setSignUpStage('sign up')
        setEmailMessage('')
        setFullNameMessage('')
        setButtonText('Sign up to be notified')
        setVerificationCode(0)
    }

    // For name validation, only allow alpha characters and spaces
    function isValidName(str) {
        var code, i, len;  
        for (i = 0, len = str.length; i < len; i++) {
          code = str.charCodeAt(i);
          if (!(code > 64 && code < 91) && // upper alpha (A-Z)
              !(code > 96 && code < 123) && // lower alpha (a-z)
              (code !== 32)) { // space
            return false;
          }
        }
        return true;
      };

    async function signUp() {
        setEmailMessage('');
        setFullNameMessage('');
        setPhoneNumberMessage('');
        if (fullName === '') {
            setFullNameMessage('Please enter your full name');
        } else if (!isValidName(fullName)) {
            setFullNameMessage('Please enter a valid name (only letters and spaces allowed)')
        } else if ((email === '') || (!email.includes('@'))) {
            setEmailMessage('Please enter a valid email');
        // } else if ((phoneNumber === '') || (phoneNumber.charAt(0) !== '+')) {
        //     setPhoneNumberMessage('Please enter a valid phone number (country code followed by the 10-digit number)');
        } else {
            var augmentedInfo = info
            if (!newsletterSignup) {
                augmentedInfo += ' (newsletter opt-out)'
            }
            setButtonText('Signing up...')
            console.log('Signing up new user')
            const signUp = `
                query SignUp {
                    signUp(
                        fullName: "${fullName}",
                        email: "${email}",
                        info: "${augmentedInfo}"
                    )
                }
            `
            const response = await API.graphql({
                query: signUp,
                variables: {},
                authMode: 'AWS_IAM'
            });
            console.log(response)
            const parsedResponse = JSON.parse(response.data.signUp);
            
            if (parsedResponse.error) {
                console.log('Error signing up')
            } else {
                console.log('Success signing up!')
                setButtonText('Confirm')
                setSignUpStage('signed up');
                setVerificationCodeMessage('');
            }
        }
    }

    async function confirmSignUp() {
        setButtonText('Confirming...');
        setVerificationCodeMessage('');
        try {
          await Auth.confirmSignUp(fullName.replace(' ', ''), verificationCode);
          console.log('success signing up!')
          setSignUpStage('signed up');
        } catch (error) {
            console.log('error confirming sign up', error);
            setVerificationCodeMessage(error.message)
            setButtonText('Confirm');
        }
    }

    async function resendConfirmationCode() {
        setVerificationCodeMessage('');
        try {
            await Auth.resendSignUp(fullName.replace(' ', ''));
            console.log('code resent successfully');
        } catch (error) {
            console.log('error resending code: ', error);
            setVerificationCodeMessage(error.message)
            setButtonText('Confirm');
        }
    }

    async function activateUser() {
        if (fullName === '') {
            console.log("Please provide the user's full name")
        } else if (phoneNumber === '') {
            console.log("Please enter a valid phone number")
        } else {
            console.log('Activating user')
            const activateUser = `
                query ActivateUser {
                    activateUser(
                        fullName: "${fullName}",
                        phoneNumber: "${phoneNumber}"
                    )
                }
            `
            const response = await API.graphql({
                query: activateUser,
                variables: {},
                authMode: 'AWS_IAM'
            });
            console.log(response)
            const parsedResponse = JSON.parse(response.data.activateUser);
            if (parsedResponse.error === true) {
                console.log('Error activating user')
            } else {
                console.log('Success activating user!')
            }
        }
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="center"> 
            <Box p="3" display="flex" flexDirection="row" alignItems="center" alignSelf="flex-end" justifyContent="flex-end">
                <Text>Already have an account?</Text>
                <Link className="mx-2 link" to={'/signin'}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Text mr="2">Sign in</Text>
                        <StyledOcticon color="icon.info" icon={ArrowRightIcon} size={24}/>
                    </Box>
                </Link>
            </Box>     
            {
                (signUpStage === 'sign up') ? (
                    <Box display="flex" pt="5" flexDirection="row" alignItems="center" justifyContent="center" flexWrap="wrap">  
                        <Box px="4" display="flex" flexDirection="column" alignItems="center">
                            <img src={ShuvLogo} alt="Shuv" height="200"/>
                            <Text textAlign="center" fontSize={50}>Welcome to Shuv!</Text>
                        </Box>            
                        <Box p="4" display="flex" m="3" flexDirection="column" className="content-box signup-content-box">
                            <Text>Shuv is currently available for early access, and space is limited. Please tell us about yourself and we'll email you as soon as your spot is open!</Text>
                            <Text mt="3" className="f3" fontWeight="bold">Enter your full name *</Text>
                            <TextInput mt="2" bg="bg.tertiary" placeholder="Jane Doe" onBlur={(e) => setFullName(e.target.value)} />
                            <Text mt="1" className="f6" color="text.danger">{fullNameMessage}</Text>
                            <Text mt="3"className="f3" fontWeight="bold">Enter your email *</Text>
                            <TextInput mt="2" bg="bg.tertiary" placeholder="user@example.com" onBlur={(e) => setEmail(e.target.value)} /> 
                            <Text mt="1" className="f6" color="text.danger">{emailMessage}</Text> 
                            <Text mt="3" className="f3" fontWeight="bold">Tell us your story</Text>
                            <Text className="f5">In a few words, tell us what's drawn you to Shuv. How will you use it?</Text>
                            <TextInput as="textarea" id="text-area" className="signup-info-box wt-resize" mt="2" bg="bg.tertiary" placeholder="What can Shuv do for you?" onBlur={(e) => processInfo(e.target.value)} /> 
                            <Box mt="2" display="flex" flexDirection="row" alignItems="center">
                                <input type="checkbox" id="newsletter-checkbox" aria-describedby="help-text-for-checkbox" onClick={(e) => setNewsletterSignup(e.target.checked)} />
                                <Text ml="2">Sign up for Shuv newsletter</Text>
                            </Box>
                            <ButtonInvisible id="signup-button" mt="3" onClick={signUp}>{buttonText}</ButtonInvisible>
                        </Box>
                    </Box>
                ) : (
                    <div />
                )
            }    
            {
                (signUpStage === 'confirm') ? (
                    <Box display="flex" pt="5" flexDirection="row" alignItems="center" justifyContent="center" flexWrap="wrap">  
                        <Box px="4" display="flex" flexDirection="column" alignItems="center">
                            <img src={ShuvLogo} alt="Shuv" height="200"/>
                            <Text textAlign="center" fontSize={50}>Please confirm your email</Text>
                        </Box>
                        <Box p="4" display="flex" m="3" flexDirection="column" className="content-box signup-content-box">
                            <Text>Please enter the Shuv verification code that was sent to your email</Text>
                            <Text mt="3" className="f3" fontWeight="bold">Shuv verification code *</Text>
                            <TextInput mt="2" bg="bg.tertiary" placeholder="Enter code" onBlur={(e) => setVerificationCode(e.target.value)} />
                            <Text mt="1" className="f6" color="text.danger">{verificationCodeMessage}</Text>      
                            <Box display="flex" alignItems="flex-start">
                                <Text as={PrimerLink} mt="2" className="link f6 resend-button" onClick={resendConfirmationCode} mr="2">Resend code</Text>
                            </Box>                 
    
                            <ButtonPrimary mt="3" onClick={confirmSignUp}>{buttonText}</ButtonPrimary>
                        </Box>
                    </Box>
                ) : (
                    <div/>
                )
            }
            {
                (signUpStage === 'signed up') ? (
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <img src={ShuvLogo} alt="Shuv" height="200"/>
                        <Text px="5" textAlign="center" fontSize={50}>Thank you for your interest in Shuv!</Text>
                        <Box p="5" display="flex" flexDirection="column" alignItems="center">
                            <Text textAlign="center">You'll receive a greeting email soon, and we'll notify you once your spot is open.</Text>
                            <ButtonInvisible>
                                <Box display="flex" flexDirection="row" alignItems="center" onClick={() => returnToSignUp()}>
                                    <StyledOcticon color="icon.info" icon={ArrowLeftIcon} size={24}/>
                                    <Text className="f4s" ml="2">Back to Sign up</Text>                          
                                </Box>
                            </ButtonInvisible>
                        </Box>
                    </Box>
                ) : (
                    <div/>
                )
            } 
            {
                (signUpStage === 'sign up') ? (
                    <Box p="4" display="flex" flexDirection="row">
                        <Text mr="1">
                            Shuv is a CI/CD tool from Loupe for managing the full lifecycle of PLC and IPC software projects. Check out our story 
                            <PrimerLink ml="1" className="f4" href="https://loupe.team/story/introducing-loupe-shuv">here</PrimerLink> 
                            .
                        </Text> 
                    </Box>  
                ) : (
                    <Box/>
                )
            }                        
        </Box>
    )
}

export default SignUpPage;