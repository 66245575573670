import '../Styles/App.css';
import '../Styles/Primer.scss';
import '../Styles/Bootstrap.scss';
import { Flex, Text, Spinner } from '@primer/components';
import ShuvLogo from '../Media/shuv-logo.png';

function LoadingPage(props){

    return (
        <div>
            <Flex p="5" flexDirection="column" alignItems="center" justifyContent="center">
                <img className="logo" src={ShuvLogo} alt="Shuv" height="200"/>
                <Spinner size="large" />
                <Text textAlign="center" fontSize={50}>Loading, please wait...</Text>
            </Flex> 
            
        </div>
    )
}

export default LoadingPage;