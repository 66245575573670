/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_mobile_analytics_app_id": "cf21d51788c04fe496044fd1b4045de0",
    "aws_mobile_analytics_app_region": "us-west-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "cf21d51788c04fe496044fd1b4045de0",
            "region": "us-west-2"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://6zver4fnufdb3dhklqocrxirfi.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-west-2:7e10378a-9495-45fe-b6c7-6ac33217d9e2",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_pFaugaMIE",
    "aws_user_pools_web_client_id": "48p5ipetcq0pkor4fnrlvdj430",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "loupe-shuv10032-prod",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
