import React, { useState, useEffect } from 'react';
import '../Styles/App.css';
import '../Styles/Globe.css';
import '../Styles/Bootstrap.scss';
import { Text, Flex, StyledOcticon, Label, Tooltip, Box, Link as PrimerLink, CircleOcticon, BorderBox, Relative } from '@primer/components';
import { CodeSquareIcon, ArrowDownIcon, CheckIcon, PlusIcon, FileCodeIcon, QuestionIcon } from '@primer/octicons-react';
import { Link } from 'react-router-dom';

function VersionLabel(props){

    const [shortVersion, setShortVersion] = useState();
    const [linkProps, setLinkProps] = useState({});
    useEffect(() => {
        if (props.version) {
            if (props.type === 'safety') {
                const crc = parseInt(props.version);
                setShortVersion(crc.toString(16).toUpperCase())
            } else {
                const splitVersion = props.version.split("_");
                if (splitVersion.length > 1) {
                    setShortVersion(splitVersion[splitVersion.length - 1]);
                } else {
                    setShortVersion(props.version);
                }
            }
        } else {
            setShortVersion('unknown');
        }
        
    }, [props.version, props.repo])

    useEffect(() => {
        if (props.repo !== undefined) {
            setLinkProps({
                href: props.repo + "/commit/" + shortVersion
            })
        } else {
            setLinkProps({})
        }
    }, [shortVersion, props.repo])

    function getColor() {
        if (props.type === 'safety') {
            return "text.warning"
        } else {
            return "text.primary"
        }
    }

    function getStatusIcon() {
        if (!props.version) {
            return (         
                <Box className="circle-container" borderRadius="50%" bg="icon.info">
                    <CircleOcticon icon={QuestionIcon} size="17" color="text.inverse" />
                </Box>
            )
        } else if (props.status === 'up to date') {
            return (         
                <Box className="circle-container" borderRadius="50%" bg="icon.success">
                    <StyledOcticon className="icon-in-circle" icon={CheckIcon} size="17" color="text.inverse" />
                </Box>
            )
        } else if (props.status === 'outdated') {
            return (
                <Box className="circle-container" borderRadius="50%" bg="icon.warning">
                    <StyledOcticon className="icon-in-circle" icon={ArrowDownIcon} size="17" color="text.inverse" />
                </Box>
            )
        } else if (props.status === 'new') {
            return (
                <Box className="circle-container" borderRadius="50%" bg="icon.info">
                    <StyledOcticon className="icon-in-circle" icon={PlusIcon} size="17" color="text.inverse" />
                </Box>
            )
        } else {
            return
        }
    }

    function getIdIcon() {
        if (props.type === 'safety') {
            return <StyledOcticon mr="1" icon={FileCodeIcon} size="15" className="color-icon-tertiary" />
        } else {
            return <StyledOcticon mr="1" icon={CodeSquareIcon} size="15" className="color-icon-tertiary" />
        }
    }

    return (
        <PrimerLink target="_blank" sx={{display: 'inline'}} {...linkProps}>
                {getStatusIcon()}
                <Label className="f4" variant="large" color="text.inverted" bg={getColor()}>
                    <Box display="flex" alignItems="center">
                        {getIdIcon()}
                        <Tooltip aria-label={props.version} noDelay>{shortVersion}</Tooltip>
                    </Box>
                </Label>            
        </PrimerLink>
               
    )
}

export default VersionLabel;