import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Amplify, { Analytics } from "aws-amplify";
import awsExports from "./aws-exports";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  withRouter
} from "react-router-dom";
Amplify.configure(awsExports);

const analyticsConfig = {
  AWSPinpoint: {
        // Amazon Pinpoint App Client ID
        appId: awsExports.aws_mobile_analytics_app_id,
        // Amazon service region
        region: awsExports.aws_mobile_analytics_app_region,
        mandatorySignIn: false,
  }
}
Analytics.configure(analyticsConfig)

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App /> 
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
