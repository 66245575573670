import React, { useState, useEffect } from 'react';
import '../Styles/App.css';
import '../Styles/Globe.css';
import '../Styles/Bootstrap.scss';
import { Text, Flex, StyledOcticon, Label, Box, Link as PrimerLink } from '@primer/components';

function TimeDiff(props){

    const [time, setTime] = useState(0);
    const refreshTime = 60 * 1000;
    useEffect(() => {
        const interval = setInterval(() => setTime(prevTime => prevTime + refreshTime), refreshTime);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const [referenceTime, setReferenceTime] = useState();
    useEffect(() => {
        setReferenceTime(props.time);
    }, [props.time])
    function getTimeDiff(time) {

        const time2 = time.replace("_", "T").replace("UTC", "Z");

        const now = Date.now();
        const timestampTemp = new Date(time2).getTime();
        const timestamp = timestampTemp;

        const time_min = (now - timestamp) / (60 * 1000);
        var time_diff = '';
        // Figure out how to render this, based on its value.
        // Less than 1 hour, so report in minutes.
        if (time_min < 60) {
            time_diff = Math.trunc(time_min);
            time_diff += (time_diff > 1) ? (' minutes ago') : (' minute ago');
        // Less than 1 day, so report in hours. 
        } else if (time_min < 60 * 24) {
            time_diff = Math.trunc(time_min / 60);
            time_diff += (time_diff > 1) ? (' hours ago') : (' hour ago');
        // Less than 1 month ago, so report in days.
        } else if (time_min < 60 * 24 * 31) {
            time_diff = Math.trunc(time_min / (60 * 24));
            time_diff += (time_diff > 1) ? (' days ago') : (' day ago');
        // Less than 1 year ago, so report in months.
        } else if (time_min < 60 * 24 * 31 * 12) {
            time_diff = Math.trunc(time_min / (60 * 24 * 31));
            time_diff += (time_diff > 1) ? (' months ago') : (' month ago');
        // Report everything else in years.
        } else {
            time_diff = Math.trunc(time_min / (60 * 24 * 31 * 12));
            time_diff += (time_diff > 1) ? (' years ago') : (' year ago');
        } 
        if (time_diff !== '0 minute ago') {
            if (props.inProgress) {
                return "(started " + time_diff + ")";
            } else {
                return time_diff
            }
        } else {
            return
        }
        
    }

    return (
        <Text ml="1" className="f3" color="text.primary">
        {
            (referenceTime) ? (
                getTimeDiff(referenceTime)
            ) : (
                <div/>
            )
        }  
        </Text>       
    )
}

export default TimeDiff;