import React, { useState, useEffect } from 'react';
import '../Styles/App.css';
import '../Styles/Primer.scss';
import '../Styles/Bootstrap.scss';
import Machine from '../Components/Machine.js';
import { Avatar, Header, Box, StyledOcticon, Button, Breadcrumb, Text, UnderlineNav, Flex, SideNav, Timeline, BranchName, TextInput } from '@primer/components';
import { RocketIcon, GearIcon, PlugIcon, SyncIcon, HomeIcon, HistoryIcon, AlertIcon, ClockIcon, BroadcastIcon, ThumbsupIcon } from '@primer/octicons-react';
import { withRouter, Switch, Route, Link, useRouteMatch, useParams } from "react-router-dom";
import { API, graphqlOperation, Analytics } from 'aws-amplify';

function MachineDetailPage(props){

    let { path, url } = useRouteMatch();

    const [activeMachine, setActiveMachine] = useState();

    useEffect(() => {
        const pathSections = props.location.pathname.split('/');
        props.machines.forEach( function(machine, index) { 
            if (machine.name === pathSections[pathSections.length - 1]) {
                console.log('Branch is ' + machine.project.branch)
                setActiveMachine(machine);
            }                             
        })
    })

    useEffect(() => {
        Analytics.record({ 
            name: 'pageChange',
            attributes: {
                user: props.user.username,
                page: 'Machine Detail Page'
            }
        })
    }, [])

    let { activeOrg } = useParams();
    useEffect(() => {
        props.updateActiveOrganizationIndexCb(activeOrg);
    },[activeOrg])

    return (
        <div>
            <UnderlineNav borderColor="transparent"/>
            <Breadcrumb m={2}>
                <Breadcrumb.Item className="h2">
                    <Link className="link" to={"/" + props.organization.name}>
                        <Avatar mr={2} square size={50} src={props.organization.avatar} />
                        <Text>{props.organization.name}</Text> 
                    </Link>                     
                </Breadcrumb.Item>
                <Breadcrumb.Item className="h2">
                    <Link className="link" to={"/" + props.organization.name + "/machines"}>
                        <Text>Machines</Text> 
                    </Link>  
                </Breadcrumb.Item>
                <Breadcrumb.Item className="h2" selected>
                {
                    (activeMachine) ? (
                        <Text>{activeMachine.name}</Text>
                    ) : (
                        <Text />
                    )
                }                          
                </Breadcrumb.Item>
            </Breadcrumb>
            {
                (activeMachine) ? (
                    <Machine
                        key={activeMachine.name}
                        organization={props.organization}
                        machine={activeMachine}
                        mode='header'
                    />
                ) : (
                    <Text />
                )
            }           

            <UnderlineNav mt="2">
                <UnderlineNav.Link sx={{borderBottomWidth: 5}} ml="3" selected={props.location.pathname === `${url}`}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <StyledOcticon mr="2" icon={HomeIcon} size={20} color="icon.secondary" />
                        <Link to={`${url}`}>
                            <Text fontSize="3" color="text.primary">Details</Text>
                        </Link>
                    </Box>
                </UnderlineNav.Link>
                <UnderlineNav.Link sx={{borderBottomWidth: 5}} selected={props.location.pathname === `${url}/timeline`}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <StyledOcticon mr="2" icon={HistoryIcon} size={20} color="icon.secondary" />
                        <Link to={`${url}/timeline`}>
                            <Text fontSize="3" color="text.primary">Timeline</Text>
                        </Link>
                    </Box>
                </UnderlineNav.Link>
                <UnderlineNav.Link sx={{borderBottomWidth: 5}} selected={props.location.pathname === `${url}/settings`}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <StyledOcticon mr="2" icon={GearIcon} size={20} color="icon.secondary" />
                        <Link to={`${url}/settings`}>
                            <Text fontSize="3" color="text.primary">Settings</Text>
                        </Link>   
                    </Box>      
                </UnderlineNav.Link>
            </UnderlineNav>
            {
                (activeMachine) ? (
                    <Switch>
                        <Route exact path={path}>
                            <MachineOverview 
                                organization={props.organization}
                                activeMachine={activeMachine}
                            />
                        </Route>
                        <Route path={`${path}/timeline`}>
                            <MachineTimeline />
                        </Route>
                        <Route path={`${path}/settings`}>
                            <MachineSettings
                                activeMachine={activeMachine} 
                                location={props.location}
                                updateOrganizationsCb={props.updateOrganizationsCb} />
                        </Route>
                    </Switch>
                ) : (
                    <div />
                )
            }
        </div>
    )
}

function MachineOverview(props) {
           
    return (
        <div className="machine-overview">
            <Flex flexDirection="column" alignItems="center">
                <Machine 
                    key={props.activeMachine.name}
                    organization={props.organization}
                    machine={props.activeMachine}
                    mode='detail'
                />
            </Flex>
        </div>
    )
}

function MachineTimeline(props) {

    const [events, setEvents] = useState([]);

    useEffect(() => {
        fetchEvents();
    //    subscribeToEventUpdates();
    },[])

    async function fetchEvents() {
        const listTimelines = `
            query MyQuery {
                listTimelines {
                    items {
                        organizationName
                        machine {
                            name
                        }
                        event
                        createdAt
                    }
                }
            }
        `
        const response = await API.graphql(graphqlOperation(listTimelines));
        console.log('Events reponse', response);
        setEvents(response.data.listTimelines.items); 
    }
    
    // function onCustomMachineUpdate(value) {    
    //     console.log('Received a machine update', value)  
    //     setOnline(value.data.onUpdateMachineByID.online);
    //     setDeploymentStatus(value.data.onUpdateMachineByID.deploymentStatus);
    //     setLatestBuiltVersion(value.data.onUpdateMachineByID.latestBuiltVersion);
    //     setMachineVersion(value.data.onUpdateMachineByID.machineVersion);
    //     setUpdateTime(value.data.onUpdateMachineByID.updateTime);
    //     setErrorMessage(value.data.onUpdateMachineByID.errorMessage);
    //     setErrorId(value.data.onUpdateMachineByID.errorId);
    //     setDescription(value.data.onUpdateMachineByID.description);
    // }

    // async function subscribeToEventUpdates() {
    //     const onMachineUpdateByID =  `
    //         subscription UpdateMachineByID {
    //             onUpdateMachineByID(id: "` + props.machine.id + `") {
    //                 latestBuiltVersion
    //                 machineVersion
    //                 name
    //                 online
    //                 deploymentStatus
    //                 errorMessage
    //                 errorId
    //                 longitude
    //                 latitude
    //                 updateTime
    //                 description
    //             }
    //         }
    //     `
    //     const subscription = await API.graphql(
    //         graphqlOperation(onMachineUpdateByID)
    //     ).subscribe({
    //         next: ({ provider, value }) => onCustomMachineUpdate(value),
    //         error: error => console.warn(error)
    //     })
    // }

    function renderEventIcon(event) {
        if (event === 'offline') {
            return <StyledOcticon icon={ClockIcon} size={36} color="icon.secondary" />
        } else if (event === 'online') {
            return <StyledOcticon icon={BroadcastIcon} size={36} color="icon.success" />
        } else if (event === 'new device') {
            return <StyledOcticon icon={ThumbsupIcon} size={36} color="icon.success" />
        }
    }

    function renderEventText(event) {
        if (event === 'offline') {
            return <Text className="f3" ml="1" color="text.primary">Machine dropped offline</Text> 
        } else if (event === 'online') {
            return <Text className="f3" ml="1" color="text.primary">Machine came online</Text>
        } else if (event === 'new device') {
            return <Text className="f3" ml="1" color="text.primary">Device was successfully onboarded</Text>
        }
    }

    return (
        <div className="machine-overview">
            <Box display="flex" m="5" flexDirection="column" alignItems="flex-start">               
            {
                (events.length > 0) ? (  
                    <Timeline clipSidebar>                         
                    {
                        events.map((item, index) => (
                            <Timeline.Item id="timeline-fix">
                                <Timeline.Badge>
                                {
                                    renderEventIcon(item.event)
                                }
                                </Timeline.Badge>
                                <Timeline.Body>
                                    <Text className="f3">{item.createdAt} -</Text>
                                    {
                                        renderEventText(item.event)
                                    }                                 
                                </Timeline.Body>
                            </Timeline.Item>
                        ))
                    }
                    </Timeline>
                ) : (
                    <Timeline.Item id="timeline-fix">
                        <Timeline.Badge>
                            <StyledOcticon icon={AlertIcon} color="icon.warning" />
                        </Timeline.Badge>
                        <Timeline.Body>
                            <Text>
                                No events recorded yet! 
                            </Text>                                  
                        </Timeline.Body>
                    </Timeline.Item>
                )
            }
            </Box>
        </div>
    )
}

function MachineSettings(props) {

    let { path, url } = useRouteMatch();

    async function updateMachineDescription(value) {
        console.log('Updating machine description')
        var inputPayload = {
            id: props.activeMachine.id,
            description: value
        }
        const updateMachine = `
            mutation UpdateMachine($input: UpdateMachineInput!) {
                updateMachine(input: $input) {
                    id
                    latestBuiltVersion
                    machineVersion
                    name
                    online
                    deploymentStatus
                    errorMessage
                    errorId
                    longitude
                    latitude
                    updateTime
                    description
                }
            }
        `
        var response = await API.graphql(graphqlOperation(updateMachine, { input: inputPayload }));
        props.updateOrganizationsCb();
    }

    return (
        <div>
            <Box display="flex" alignItems="flex-start">
                <SideNav m="4" bordered aria-label="Main">
                    <SideNav.Link selected={props.location.pathname === `${url}`}>
                        <Link to={`${url}`}>
                            <Text fontSize="2" color="text.primary">Information</Text>
                        </Link>
                    </SideNav.Link>
                    <SideNav.Link selected={props.location.pathname === `${url}/pipeline`}>
                        <Link to={`${url}/pipeline`}>
                            <Text fontSize="2" color="text.primary">Pipeline</Text>
                        </Link>
                    </SideNav.Link>
                </SideNav>
                <Switch>
                    <Route exact path={path}>
                        <Flex mr="2" mt="2" flexDirection="column" alignItems="flex-start" className="fill-width">
                            <Text pt="2" pb="2" className="h2 border-bottom fill-width">Machine Information</Text>
                            <Flex mt="1" flexDirection="column" className="fill-width">
                                <Text mb="2" className="f3" fontWeight="bold">Description</Text>
                                <TextInput defaultValue={props.activeMachine.description} onBlur={(e) => updateMachineDescription(e.target.value)}/>
                            </Flex>
                        </Flex>         
                    </Route>
                    <Route path={`${path}/pipeline`}>
                        <Flex mr="2" mt="2" flexDirection="column" alignItems="flex-start" className="fill-width">
                            <Text py="2" className="h2 border-bottom fill-width">Pipeline Settings</Text>
                            <Flex mt="1" flexDirection="column" className="fill-width">
                                <Text mb="2" className="f3" fontWeight="bold">Pipeline Name</Text>
                                <TextInput readOnly={true} defaultValue={props.activeMachine.project.alias}/>
                            </Flex>
                            <Flex mt="3" flexDirection="column" className="fill-width">
                                <Text mb="2" className="f3" fontWeight="bold">Deployments</Text>
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <input type="checkbox" checked="checked" aria-describedby="help-text-for-checkbox" />
                                    <Text ml="2">Enable Deployments</Text>
                                </Box>
                            </Flex>
                            
                        </Flex> 
                    </Route>
                </Switch>
            </Box>

        </div>
        
    )
}


export default withRouter(MachineDetailPage);


