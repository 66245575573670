import React, { useState, useEffect } from 'react';
import '../Styles/App.css';
import '../Styles/Bootstrap.scss';
import  Amplify from 'aws-amplify';
import { ButtonDanger, Text, Label, CircleOcticon, StyledOcticon, Flash, Box, Button, Dialog } from '@primer/components';
import { CodeSquareIcon } from '@primer/octicons-react';
import { Link } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';


function MessagePopup(props) {

    const returnFocusRef = React.useRef(null);

    return (
        <Dialog
            className="user-dialog"
            returnFocusRef={returnFocusRef}
            isOpen={props.open}
            onDismiss={props.closePopupCb}
            aria-labelledby="header-id"
            borderWidth="2"
            borderStyle="solid"
        >
            <Box borderWidth="2" borderStyle="solid" borderRadius="5px">
                <Dialog.Header>
                    {props.titleJsx}
                </Dialog.Header>
                
                <Box p={3} display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                    {props.bodyJsx}
                </Box>
            </Box>
        </Dialog>
    )

}

export default MessagePopup;