import React, { useState, useEffect } from 'react';
import '../Styles/App.css';
import '../Styles/Bootstrap.scss';
import VersionLabel from './VersionLabel.js';
import CloudUploadIcon from '../Media/cloud-upload-icon.png';
import { Text, CircleOcticon, StyledOcticon, Flex, BranchName, Link as PrimerLink, Flash, Spinner, ButtonClose } from '@primer/components';
import { XCircleFillIcon, CheckCircleFillIcon, GearIcon, UploadIcon, RepoPullIcon, UnverifiedIcon, FileZipIcon, XIcon, BeakerIcon, AlertIcon } from '@primer/octicons-react';
import { API, graphqlOperation } from 'aws-amplify';
import TimeDiff from './TimeDiff.js';
import { Link } from 'react-router-dom';

const buildStage = {
    INIT: 'Init',
    CHECKOUT: 'Checkout',
    AS_BUILD: 'AS Build', 
    TEST: 'Test',
    PACKAGE: 'Package Simulator Artifacts', 
    INSTALLER_UPLOAD: 'Upload'
}
const buildPhase = {
    INIT: 'INIT',
    READY: 'READY',
    BUILDING: 'BUILDING', 
    DONE: 'COMPLETE'
}
const buildStatus = {
    SUCCESS: 'SUCCESS', 
    FAILURE: 'FAILURE', 
    ABORTED: 'ABORTED',
    UNSTABLE: 'UNSTABLE'
}

function LatestBuild(props){

    const [commit, setCommit] = useState('');
    const [status, setStatus] = useState('');
    const [stage, setStage] = useState('');
    const [phase, setPhase] = useState('');
    const [version, setVersion] = useState('');
    const [buildUrl, setBuildUrl] = useState('');
    const [pipelineUrl, setPipelineUrl] = useState('');
    const [projectAlias, setProjectAlias] = useState('');
    const [startTime, setStartTime] = useState('');
    const [tag, setTag] = useState('');

    useEffect(() => {   
        if(props.project.latestBuildID !== null){
            fetchBuildInfo();
            subscribeToBuildUpdates(); 
        }
    }, [props.project.alias])

    async function fetchBuildInfo() {
        console.log('Build ID: ' + props.project.latestBuildID)
        const getBuildInfo = `
            query MyQuery {
                getBuild(id: "` + props.project.latestBuildID + `") {
                    buildUrl
                    commit
                    id
                    phase
                    pipelineUrl
                    stage
                    startTime
                    status
                    version
                    tag
                    project {
                        alias
                    }
                }
            }
        `
        const response = await API.graphql(graphqlOperation(getBuildInfo));
        console.log('Build info response: ', response);
        setCommit(response.data.getBuild.commit);
        setStatus(response.data.getBuild.status);
        setStage(response.data.getBuild.stage);
        setPhase(response.data.getBuild.phase);
        setVersion(response.data.getBuild.version);
        setBuildUrl(response.data.getBuild.buildUrl);
        setPipelineUrl(response.data.getBuild.pipelineUrl);
        setStartTime(response.data.getBuild.startTime);  
        setTag(response.data.getBuild.tag);
        setProjectAlias(response.data.getBuild.project.alias)
    }

    function onCustomBuildUpdate(value) {  
        console.log('Received a build update', value)         
        // Update the relevant build. 
        setCommit(value.data.onUpdateBuildByID.commit);
        setStatus(value.data.onUpdateBuildByID.status);
        setStage(value.data.onUpdateBuildByID.stage);
        setPhase(value.data.onUpdateBuildByID.phase);
        setVersion(value.data.onUpdateBuildByID.version);
        setBuildUrl(value.data.onUpdateBuildByID.buildUrl);
        setPipelineUrl(value.data.onUpdateBuildByID.pipelineUrl);
        setStartTime(value.data.onUpdateBuildByID.startTime);   
        setTag(value.data.onUpdateBuildByID.tag);
    }

    async function subscribeToBuildUpdates() {
        const onBuildUpdate =  `
            subscription UpdateBuildByID {
                onUpdateBuildByID(id: "` + props.project.latestBuildID + `") {
                    buildUrl
                    commit
                    id
                    phase
                    pipelineUrl
                    stage
                    startTime
                    status
                    version
                    tag
                }
            }
        `
        const subscription = await API.graphql(
            graphqlOperation(onBuildUpdate)
        ).subscribe({
            next: ({ provider, value }) => onCustomBuildUpdate(value),
            error: error => console.warn(error)
        })
    }

    function updateFlashVariant() {
        if (phase !== buildPhase.INIT) {
            if (phase === 'BUILDING') {
                return 'default';
            } else {
                if (status === buildStatus.SUCCESS) {
                    return 'success';
                } else if (status === buildStatus.FAILURE) {
                    return 'danger';
                } else if (status === buildStatus.ABORTED) {
                    return 'danger';
                } else if (status === buildStatus.UNSTABLE) {
                    return 'warning';
                }                   
            }  
        } else {
            return 'warning';
        } 
    }

    async function abortPipelineBuild() {
        // Call a Lambda that aborts the build.
        const abortPipelineBuild = `
            query AbortPipelineBuild {
                abortPipelineBuild(
                    alias: "${props.project.alias}"
                )
            }
        `
        const response = await API.graphql(graphqlOperation(abortPipelineBuild));
        const parsedResponse = JSON.parse(response.data.abortPipelineBuild);
        console.log(parsedResponse)
    }

    const [buildInfo, setBuildInfo] = useState();
    useEffect(() => {
        var jsx = [];
        var statusMsg = '';
        // Check to see if the pipeline has ever been built. 
        if (phase !== buildPhase.INIT) {
            if (phase === buildPhase.BUILDING) {
                if (stage === buildStage.INIT) {
                    jsx.push(<Spinner size="medium" />);
                    statusMsg = 'Initializing build';
                } else if (stage === buildStage.CHECKOUT) {
                    jsx.push(<StyledOcticon color="icon.secondary" icon={RepoPullIcon} className='anim-pulse' size={36}/>);
                    statusMsg = 'Checking out';
                } else if (stage === buildStage.AS_BUILD) {
                    jsx.push(<StyledOcticon color="icon.secondary" icon={GearIcon} className='icn-spinner' size={36}/>);
                    statusMsg = 'Building';
                } else if (stage === buildStage.TEST) {
                    jsx.push(<StyledOcticon color="icon.secondary" icon={BeakerIcon} className='anim-pulse' size={36}/>);
                    statusMsg = 'Running tests for';
                } else if (stage === buildStage.INSTALLER_UPLOAD) {
                    //jsx.push(<StyledOcticon color="icon.secondary" icon={UploadIcon} size={36}/>);
                    jsx.push(<img className="cloud-upload-icon icn-bounce mr-2" src={CloudUploadIcon} alt="Up" height="36"/>);
                    statusMsg = 'Uploading installer for';
                } else if (stage === buildStage.PACKAGE) {
                    jsx.push(<StyledOcticon color="icon.secondary" icon={FileZipIcon} size={36}/>);
                    statusMsg = 'Packaging artifacts for';
                } else {
                    jsx.push(<StyledOcticon color="icon.secondary" icon={GearIcon} className='icn-spinner' size={36}/>);
                    statusMsg = 'Building';
                }        
            } else {
                if (status === buildStatus.SUCCESS) {
                    jsx.push(<StyledOcticon color="icon.success" icon={CheckCircleFillIcon} size={36} />);
                    statusMsg = 'Successfully built';
                } else if (status === buildStatus.FAILURE) {
                    jsx.push(<StyledOcticon color="icon.danger" icon={XCircleFillIcon} size={36} />);
                    statusMsg = 'Failed to build';
                } else if (status === buildStatus.ABORTED) {
                    jsx.push(<StyledOcticon color="icon.danger" icon={XCircleFillIcon} size={36} />);
                    statusMsg = 'Aborted building';
                } else if (status === buildStatus.UNSTABLE) {
                    jsx.push(<StyledOcticon color="icon.warning" icon={AlertIcon} size={36} />);
                    statusMsg = 'Finished building';   
                }                   
            }   
            jsx.push(<Text mx="1" className={props.fontSize + " no-multiline"} color="text.primary">{statusMsg}</Text>);
            // Include the Git info.
            var branchUrl;
            // When building from a branch trigger.
            if (props.project.trigger === 'branch') {
                if (stage === buildStage.INIT) {
                    // Don't append any info here.
                } else if (stage === buildStage.CHECKOUT) {
                    branchUrl = props.project.repository + '/tree/' + props.project.branch;
                    jsx.push(<BranchName ml="1" href={branchUrl} className={props.fontSize}>{props.project.branch}</BranchName>);
                } else if ((stage === buildStage.AS_BUILD) || (stage === buildStage.INSTALLER_UPLOAD) || (stage === buildStage.PACKAGE) || (stage === buildStage.TEST)) {
                    if ((version !== '') || (commit !== '')) {
                        if (version !== '') {
                            jsx.push(<VersionLabel version={version} repo={props.project.repository} />);
                        } else if (commit !== '') {
                            jsx.push(<VersionLabel version={commit.substring(0,7)} repo={props.project.repository} />);
                        }
                        if (props.project.branch !== '') {
                            jsx.push(<Text ml="1" className={props.fontSize} color="text.primary">on</Text>);
                            branchUrl = props.project.repository + '/tree/' + props.project.branch;
                            jsx.push(<BranchName ml="1" href={branchUrl} className={props.fontSize}>{props.project.branch}</BranchName>);
                        } 
                    }
                }
            // When building from a tag trigger. 
            } else if (props.project.trigger === 'tag') {
                if (tag !== '') {
                    jsx.push(<VersionLabel version={tag} repo={props.project.repository} />);
                } 
            }
            // Include the timestamp info.
            if (startTime !== '') {
                if (phase === buildPhase.BUILDING) {
                    jsx.push(<TimeDiff time={startTime} inProgress={true} />);
                } else {
                    jsx.push(<TimeDiff time={startTime} inProgress={false} />);
                }
            }  
            // Include the 'abort' button if a build is active. 
            if (phase === buildPhase.BUILDING) {
                // jsx.push(<ButtonDanger ml="3"><StyledOcticon icon={XIcon} size={24} color="icon.primary"/></ButtonDanger>)
                jsx.push(<ButtonClose ml="5" variant="large" onClick={() => abortPipelineBuild()} />)
            }
            // if (buildUrl !== '') {
            //     jsx.push(<PrimerLink ml="1" href={buildUrl + "console"}>
            //                 <StyledOcticon className="color-icon-secondary" icon={TerminalIcon} size={36} />
            //                 </PrimerLink>);
            // }  
        } else {
            jsx.push(<StyledOcticon color="icon.secondary" icon={UnverifiedIcon} size={36}/>);
            jsx.push(<Text mx="1" className={props.fontSize} color="text.primary">This pipeline hasn't been built yet. Please visit the</Text>)
            jsx.push(<Link to={`/${props.project.organization.name}/pipelines/${props.project.alias}/settings`}><Text fontSize="3" className="link">pipeline settings</Text></Link>)
            jsx.push(<Text ml="1" className={props.fontSize} color="text.primary">page for more info.</Text>);
        }          
        setBuildInfo(jsx)                                   
    }, [version, commit, phase, stage, status, tag, startTime, props.project, props.build, props.fontSize]);

    return (
        <div>
            <Flash variant={updateFlashVariant()}>
                <Flex alignItems="center" flexWrap="wrap" > 
                    {buildInfo} 
                </Flex>
            </Flash>
        </div>
    )
}

export default LatestBuild;