import React from 'react';
import '../Styles/App.css';
import '../Styles/Globe.css';
import '../Styles/Bootstrap.scss';
import { Text, Flex, StyledOcticon, Label } from '@primer/components';
import { Link } from 'react-router-dom';
import AsIcon from '../Media/as-icon.png';

function ProjectLabel(props){

    return (
        <Link to={"/" + props.orgName + "/pipelines/" + props.projectName}>
            <Label mr="3" variant="large" outline borderColor="border.info" color="text.link" className="f4">
                <Flex alignItems="center">
                    <img className="as-icon mr-2" src={AsIcon} alt="Shuv" height="20"/>  
                    <Text>{props.projectName}</Text> 
                </Flex>  
            </Label>
        </Link>
        
    )
}

export default ProjectLabel;