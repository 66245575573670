import React, { useState, useEffect } from 'react';
import '../Styles/App.css';
import '../Styles/Globe.css';
import '../Styles/Bootstrap.scss';
import { Text, Flex, StyledOcticon, Label, Link as PrimerLink, BranchName } from '@primer/components';
import { Link } from 'react-router-dom';
import AsIcon from '../Media/as-icon.png';
import PuzzlePieceIcon from '../Media/puzzle-piece-icon.png';
import { ArrowDownIcon, GitBranchIcon, RepoIcon, TagIcon } from '@primer/octicons-react';
import RepositoryType from '../Components/RepositoryType.js';

function ProjectComposition(props){

    const [triggerContent, setTriggerContent] = useState([]);
    const [configContent, setConfigContent] = useState([]);

    // Update the trigger content when any of its dependencies change. 
    useEffect(() => {
        const jsx = [];
        if (props.project.trigger === 'branch') {
            jsx.push(<StyledOcticon mr="1" icon={GitBranchIcon} size={25} color="icon.primary" />);
            if (props.project.repositoryType === RepositoryType.GITHUB) {
                jsx.push(<BranchName className="f4" href={props.project.repository + "/tree/" + props.project.branch}>{props.project.branch}</BranchName>);
            } else if (props.project.repositoryType === RepositoryType.BITBUCKET) {
                jsx.push(<BranchName className="f4" href={props.project.repository + "/src/" + props.project.branch}>{props.project.branch}</BranchName>);
            }
        } else if (props.project.trigger === 'tag') {
            jsx.push(<StyledOcticon mr="1" icon={TagIcon} size={25} color="icon.primary" />);
            if (props.mode === 'detail') {
                jsx.push(<StyledOcticon mr="1" icon={TagIcon} size={25} color="icon.primary" />);
                jsx.push(<StyledOcticon mr="1" icon={TagIcon} size={25} color="icon.primary" />);
            }         
        }
        setTriggerContent(jsx);
    }, [props.project.trigger, props.project.repositoryType, props.project.repository, props.project.branch]);

    // Update the configuration content when any of its dependencies change.
    useEffect(() => {
        const jsx = [];        
        jsx.push(<img className="puzzle-icon mr-1" src={PuzzlePieceIcon} alt="Configuration" height="30"/>)
        if (props.project.repositoryType === RepositoryType.GITHUB) {
            jsx.push(
                <a href={props.project.repository + "/tree/" + props.project.branch + '/' + props.project.asProject + "/Physical/" + props.project.physicalConfiguration}>
                    <Text className="f4" color="text.primary">{props.project.physicalConfiguration}</Text> 
                </a>
            )
        } else if (props.project.repositoryType === RepositoryType.BITBUCKET) {
            jsx.push(
                <a href={props.project.repository + "/src/" + props.project.branch + '/' + props.project.asProject + "/Physical/" + props.project.physicalConfiguration}>
                    <Text className="f4" color="text.primary">{props.project.physicalConfiguration}</Text> 
                </a>
            )
        }   
        setConfigContent(jsx);
    }, [props.project.repositoryType, props.project.repository, props.project.branch, props.project.asProject, props.project.physicalConfiguration]);

    return (
        <div>
        {
            (props.mode === 'detail') ? (
                <Flex alignItems="center" flexDirection="column">                  
                    <Flex alignItems="center">
                        <StyledOcticon mr="1" icon={RepoIcon} size={25} color="icon.primary" />
                        <PrimerLink className="f4 mt-2" href={props.project.repository}>{props.project.repository.substring(props.project.repository.lastIndexOf("/")+1)}</PrimerLink>
                    </Flex>
                    <StyledOcticon mt="1" icon={ArrowDownIcon} size={25} color="icon.secondary" />
                    <Flex mt="1" alignItems="center">
                        {triggerContent}
                    </Flex> 
                    <StyledOcticon mt="1" icon={ArrowDownIcon} size={25} color="icon.secondary" />
                    <Flex mt="1" mb="3" alignItems="center">
                        {configContent}
                    </Flex>
                </Flex>   
            ) : (
                <Flex flexWrap="wrap" >
                    <Flex mt="2" alignItems="center">
                        <StyledOcticon mr="1" icon={RepoIcon} size={25} color="icon.primary" />
                        <a href={props.project.repository}>
                            <Text className="f3 mr-4" color="text.primary">{props.project.repository.substring(props.project.repository.lastIndexOf("/")+1)}</Text>
                        </a>
                    </Flex>    
                    <Flex mt="2" mr="4" alignItems="center">
                        {triggerContent}
                    </Flex>    
                    <Flex mt="2" alignItems="center">
                        {configContent}
                    </Flex> 
                </Flex> 
            )
        }   
        </div>
                
    )
}

export default ProjectComposition;