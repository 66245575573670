import { useState, useEffect } from 'react';
import '../Styles/App.css';
import '../Styles/Primer.scss';
import '../Styles/Bootstrap.scss';
import { Flex, Text, TextInput, StyledOcticon, ButtonPrimary, Box, Dropdown, ButtonInvisible, Flash, Button, Avatar, Spinner, ProgressBar, CircleOcticon } from '@primer/components';
import { CheckIcon, CopyIcon, FileIcon, GitBranchIcon, QuestionIcon, TagIcon } from '@primer/octicons-react';
import DevopsAdmin from '../Media/devops-admin.png';
import $ from "jquery";
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { Link, Switch, Route, useHistory, useRouteMatch, useParams } from 'react-router-dom';
import RepositoryType from '../Components/RepositoryType.js';
import MessagePopup from '../Components/MessagePopup';

const defaultRevInfoPath = 'Logical/Shuv/Tasks/Revision/RevInfo.var';

function NewProjectPage(props){

    const [selectedOrganizationIndex, setSelectedOrganizationIndex] = useState(0);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [repoUrl, setRepoUrl] = useState('');
    const [repoType, setRepoType] = useState(RepositoryType.UNKNOWN);
    const [asProjectPath, setAsProjectPath] = useState('');
    const [revInfoPath, setRevInfoPath] = useState(defaultRevInfoPath);
    const [includeSafeApplication, setIncludeSafeApplication] = useState(false);
    const [safeApplicationName, setSafeApplicationName] = useState('');
    const [safeApplicationUsername, setSafeApplicationUsername] = useState('');
    const [safeApplicationPassword, setSafeApplicationPassword] = useState('');
    const [asPhysicalConfiguration, setAsPhysicalConfiguration] = useState('');
    const [asSimulationConfiguration, setAsSimulationConfiguration] = useState('');
    const [generateSimInstaller, setGenerateSimInstaller] = useState(false);
    const [includeSimInstallerUserFiles, setIncludeSimInstallerUserFiles] = useState(false);
    const [simInstallerUserFiles, setSimInstallerUserFiles] = useState('');
    const [includeSimInstallerHmiFiles, setIncludeSimInstallerHmiFiles] = useState(false);
    const [simInstallerHmiFiles, setSimInstallerHmiFiles] = useState('');
    const [trigger, setTrigger] = useState('');
    const [branch, setBranch] = useState('');
    const [projectButtonText, setProjectButtonText] = useState('Create Pipeline');
    const [errorMsg, setErrorMsg] = useState('');
    const [username, setUsername] = useState('');
    const [token, setToken] = useState('');
    const [projectCreationStatus, setProjectCreationStatus] = useState('');
    const [progressStartTime, setProgressStartTime] = useState(0);
    const [stepTime, setStepTime] = useState(1);
    const [credentialEntryContent, setCredentialEntryContent] = useState();

    const history = useHistory();

    useEffect(() => {
        initializeProject();
    }, [])

    function initializeProject() {
        setSelectedOrganizationIndex(props.activeOrganizationIndex)
        // Initialize trigger radio button to point to branch.
        $("#trigger-branch").trigger("click")
        setTrigger("branch")
    }

    let { activeOrg } = useParams();
    useEffect(() => {
        props.updateActiveOrganizationIndexCb(activeOrg);
    },[activeOrg])

    const [time, setTime] = useState(0);
    const refreshTime = 100;
    useEffect(() => {
        const interval = setInterval(() => setTime(prevTime => prevTime + refreshTime), refreshTime);
        return () => {
            clearInterval(interval);
        };
    }, []);
    useEffect(() => {
        if (projectCreationStatus === 'Generating backend resources...') {
            setStepTime(3500); 
        } else if (projectCreationStatus === 'Packaging up Shuv artifacts...') {
            setStepTime(30000);
        } else {
            setStepTime(1);
        }
    }, [time])

    // Handle the additional info popup. 
    const [popupOpen, setPopupOpen] = useState(false);
    const [popupTitle, setPopupTitle] = useState('');
    const [popupInfo, setPopupInfo] = useState('');
    function openAdditionalInfo(title, info) {
        setPopupTitle(title);
        setPopupInfo(info);
        setPopupOpen(true);
    }

    function validateRepoUrl(url) {
        console.log('Validating repo URL...')     
        var type = RepositoryType.UNKNOWN;
        // Perform a simple check to see if we're dealing with Github or Bitbucket repo. 
        // Also, do some pre-processing on the URL so that we get it in an expected format later. 
        if (url.toLowerCase().includes(RepositoryType.GITHUB)) {           
            type = RepositoryType.GITHUB;
        } else if (url.toLowerCase().includes(RepositoryType.BITBUCKET)) {
            type = RepositoryType.BITBUCKET;
        }   
        console.log('Repo type is ' + type)
        const splitUrl = url.toLowerCase().split('/') 
        // Find occurrence of repo root (i.e. 'bitbucket.org', 'github.com', etc).
        for (let i = 0; i < splitUrl.length; i++) {
            if (splitUrl[i] === type) {
                if ((splitUrl.length-1) >= (i+2)) {
                    setRepoType(type)
                    setRepoUrl(`https://${type}/${splitUrl[i+1]}/${splitUrl[i+2]}`)
                    return;
                }
            }
        }
        setRepoType(RepositoryType.UNKNOWN)
        setRepoUrl('')
        console.log('Error parsing the repo URL')
    }

    function updateErrorMsg(msg) {
        setErrorMsg(msg)
        setProjectButtonText('Create Pipeline')
    }

    async function createProject() {
        setErrorMsg('');
        // Validate inputs. 
        if (name === '') {
            updateErrorMsg('Pipeline name must not be empty');
        } else if (name.includes('-')) {
            updateErrorMsg('Hyphens (-) are not allowed in pipeline name');
        } else if ((repoType !== RepositoryType.GITHUB) && (repoType !== RepositoryType.BITBUCKET)) {
            updateErrorMsg('Please enter a valid Github or Bitbucket URL');
        } else if ((username === '') || (token === '')) {
            updateErrorMsg('Please enter valid credentials for accessing the specified repository')
        } else if (asProjectPath === '') {
            updateErrorMsg('Please enter a valid AS Project Path')
        } else if (asPhysicalConfiguration === '') {
            updateErrorMsg('Please enter a valid Physical Configuration')
        } else if (asSimulationConfiguration === '') {
            updateErrorMsg('Please enter a valid Simulation Configuration')
        } else if ((trigger === 'branch') && (branch === '')) {
            updateErrorMsg('Please enter a valid trigger Branch name')
        } else {
            setProjectButtonText('Creating Pipeline...')
            setProjectCreationStatus('Generating backend resources...');
            setProgressStartTime(time);
            console.log('Creating backend pipeline')
            // Create the Jenkins job first. 
            const createPipeline = `
                query CreatePipeline {
                    createPipeline(
                        url: "${repoUrl}",
                        organization: "${props.organizations[selectedOrganizationIndex].name}",
                        branch: "${branch}",
                        username: "${username}",
                        token: "${token}",
                        alias: "${name}",
                        type: "${repoType}"
                    )
                }
            `
            var parsedResponse1;
            try {
                const response1 = await API.graphql(graphqlOperation(createPipeline));
                console.log(response1)
                parsedResponse1 = JSON.parse(response1.data.createPipeline);
            } catch(e) {
                console.log('Error creating pipeline', e)
                updateErrorMsg('Creating pipeline: ' + e.errors[0].message)
                return;
            }          

            // Then create the actual project record in the DynamoDB table. 
            console.log('Creating project record')
            const createProject = `
                mutation CreateProject {
                    createProject(input: {
                        alias: "${name}",
                        asProject: "${asProjectPath}",
                        type: "as", 
                        branch: "${branch}",
                        physicalConfiguration: "${asPhysicalConfiguration}",
                        simulationConfiguration: "${asSimulationConfiguration}",
                        generateSimInstaller: ${generateSimInstaller},
                        simInstallerUserFiles: "${simInstallerUserFiles}",
                        simInstallerHmiFiles: "${simInstallerHmiFiles}",
                        revInfoPath: "${revInfoPath}",
                        safeApplicationPath: "${safeApplicationName}", 
                        description: "${description}",
                        organizationID: "${props.organizations[selectedOrganizationIndex].id}",
                        organizationName: "${props.organizations[selectedOrganizationIndex].name}",
                        pipeline: "${parsedResponse1.jobName}",
                        repository: "${repoUrl}",
                        trigger: "${trigger}",
                        iotProvisioningTemplateName: "",
                        iotPolicyName: "",
                        iotCertificateID: "",
                        repositoryType: "${repoType}"
                    }) {
                        id
                    }
                }
            `
            var projectId;
            try {
                const response2 = await API.graphql(graphqlOperation(createProject));
                projectId = response2.data.createProject.id;
                console.log(response2)
            } catch(e) {
                console.log('Error creating project', e)
                updateErrorMsg('Project record creation: ' + e.errors[0].message)
                return; 
            }

            // Then create the build object in the DynamoDB table that will be linked with this project.  
            console.log('Creating build record')
            const createBuild = `
                mutation CreateBuild {
                    createBuild(input: {
                        buildUrl: "",
                        commit: "",
                        phase: "INIT",
                        pipelineUrl: "",
                        projectID: "${projectId}",
                        organizationID: "${props.organizations[selectedOrganizationIndex].id}",
                        organizationName: "${props.organizations[selectedOrganizationIndex].name}",
                        stage: "",
                        startTime: "",
                        status: "",
                        tag: "",
                        version: "",
                        safetyVersion: ""
                    }) {
                        id
                    }
                }
            `
            var buildId;
            try {
                const response3 = await API.graphql(graphqlOperation(createBuild));
                buildId = response3.data.createBuild.id;
                console.log(response3)
            } catch(e) {
                console.log('Error creating build', e)
                updateErrorMsg('Build record creation: ' + e.errors[0].message) 
                return;    
            }

            // Then update the latestBuildID of the project record.  
            console.log('Updating latest build ID')
            const updateBuild = `
                mutation UpdateProject {
                    updateProject(input: {
                        id: "${projectId}",
                        latestBuildID: "${buildId}"
                    }) {
                        id
                    }
                }
            `
            try {
                const response4 = await API.graphql(graphqlOperation(updateBuild));
                console.log(response4)
            } catch(e) {
                console.log('Error updating build record', e)
                updateErrorMsg('Build record update: ' + e.errors[0].message)
                return;  
            }

            // Upload the create.log file to S3, to create the required path key.
            console.log('Creating backend storage resources')
            console.log('Configuring storage for the project')
            Storage.configure({
                level: 'public', 
                customPrefix: {
                    public: 'userData/' + props.organizations[selectedOrganizationIndex].name + '/' + name + '/artifacts/'
                }
            });
            try {
                const response5 = await Storage.put('create.log', 'S3 bucket successfully created');
            } catch(e) {
                console.log('Error setting up storage', e)
                updateErrorMsg('Storage setup: ' + e.errors[0].message)
                return;
            }

            // Retrieve the generic Shuv.zip, customize it, and place it in this project's S3 storage location. 
            console.log('Creating Shuv.zip artifacts')
            setProjectCreationStatus('Packaging up Shuv artifacts...');
            setProgressStartTime(time);
            const createArtifacts = `
                query CreateArtifacts {
                    createArtifacts(
                        organization: "${props.organizations[selectedOrganizationIndex].name}",
                        projectAlias: "${name}"
                    )
                }
            `
            try {
                const response6 = await API.graphql(graphqlOperation(createArtifacts));
                console.log(response6)
            } catch(e) {
                console.log('Error creating artifacts', e)
                updateErrorMsg('Artifact setup: ' + e.errors[0].message)
                return;
            }

            // Now that all records have been created, trigger a refresh at the app level. 
            props.updateOrganizationsCb();

            // And navigate to the newly created project's page.
            history.push("/" + props.organizations[selectedOrganizationIndex].name + "/pipelines/" + name)
        }
    }

    function checkRepo() {
        // Do nothing here for now. 
    }

    function updateOrganization(event, index) {
        setSelectedOrganizationIndex(index);
    }

    useEffect(() => {
        console.log('Rendering credential entry...')
        if (repoType === RepositoryType.GITHUB) {
            setCredentialEntryContent(
                <Box display="flex" mt="3" flexDirection="column">
                    <Box mb="2" display="flex" flexDirection="row" alignItems="center">
                        <Text mr="2" className="f3" fontWeight="bold">Repository Credentials</Text>
                        <CircleOcticon icon={QuestionIcon} size={18} onClick={() => openAdditionalInfo('Repository Credentials', "The username can be either your email or your Github handle. The Personal Access Token can be created by navigating to your user settings, and selecting 'Developer settings'.")} />
                    </Box> 
                    <Text mb="2" className="f5">Provide a Github username and an associated Personal Access Token that has read access to this repo.</Text>
                    <Box display="flex" flexDirection="row" alignContent="center">
                        <TextInput className="fill-width" mr="2" placeholder="Username" onBlur={(e) => setUsername(e.target.value)} />  
                        <TextInput type="password" className="fill-width" placeholder="Personal Access Token" onBlur={(e) => setToken(e.target.value)} /> 
                    </Box> 
                </Box>
            )
        } else if (repoType === RepositoryType.BITBUCKET) {
            setCredentialEntryContent(
                <Box display="flex" mt="3" flexDirection="column">
                    <Box mb="2" display="flex" flexDirection="row" alignItems="center">
                        <Text mr="2" className="f3" fontWeight="bold">Repository Credentials</Text>
                        <CircleOcticon icon={QuestionIcon} size={18} onClick={() => openAdditionalInfo('Repository Credentials', "The username must be your email (i.e. the one registered with BitBucket).")} />
                    </Box> 
                    <Text mb="2" className="f5">Provide a BitBucket username and an associated App Password that has read access to this repo.</Text>
                    <Box display="flex" flexDirection="row" alignContent="center">
                        <TextInput className="fill-width" mr="2" placeholder="Username" onBlur={(e) => setUsername(e.target.value)} />  
                        <TextInput className="fill-width" placeholder="App Password" onBlur={(e) => setToken(e.target.value)} /> 
                    </Box> 
                </Box>
            )
        } else {
            setCredentialEntryContent(
                <Box display="flex" mt="3" flexDirection="column">
                    <Text  className="f3" fontWeight="bold">Repository Credentials</Text>
                    <Text className="f5">The credential format depends on the Repository URL. Please enter a valid Repository URL (Github or Bitbucket) so we can decide what type of credentials to require.</Text> 
                </Box>
            )
        }
    }, [repoType])

    function toggleInstallerGeneration() {
        if (generateSimInstaller) {
            setGenerateSimInstaller(false)
            setIncludeSimInstallerUserFiles(false)
            setIncludeSimInstallerHmiFiles(false)
            setSimInstallerUserFiles('')
            setSimInstallerHmiFiles('')
        } else {
            setGenerateSimInstaller(true)
        }
    }

    function toggleSafetyInclusion() {
        if (includeSafeApplication) {
            setIncludeSafeApplication(false)
            setSafeApplicationName('')
            setSafeApplicationUsername('')
            setSafeApplicationPassword('')
        } else {
            setIncludeSafeApplication(true)
        }
    }

    function toggleUserFileInclusion() {
        if (includeSimInstallerUserFiles) {
            setIncludeSimInstallerUserFiles(false)
            setSimInstallerUserFiles('')
        } else {
            setIncludeSimInstallerUserFiles(true)
        }
    }

    function toggleHmiFileInclusion() {
        if (includeSimInstallerHmiFiles) {
            setIncludeSimInstallerHmiFiles(false)
            setSimInstallerHmiFiles('')
        } else {
            setIncludeSimInstallerHmiFiles(true)
        }
    }

    function projectCreationContent() {
        return (
            <Flex mt="5" flexDirection="row" justifyContent="center">
                <Flex className="new-project" flexDirection="column" alignItems="flex-start">
                    <Text className="h1">
                        Create a new pipeline
                    </Text>
                    <Text className="f3">
                        A pipeline points to a user repository and defines which portions of the repository trigger a build.
                    </Text>

                    <Text mt="6" className="h2">General</Text>
                    <Box className="separator" />
                    <Box mt="3" display="grid" gridTemplateColumns="auto auto auto" gridTemplateRows="1fr 1fr" gridAutoFlow="column" gridColumnGap={3}>

                        <Box mb="2" display="flex" flexDirection="row" alignItems="center">
                            <Text mr="2" className="f3" fontWeight="bold">Organization</Text>
                            <CircleOcticon icon={QuestionIcon} size={18} onClick={() => openAdditionalInfo('Organization', "From the list of organizations that you belong to, select the one where the new pipeline should live.")} />
                        </Box> 

                            <Dropdown>
                                <Dropdown.Button>
                                    <Avatar mr={2} square size={24} src={props.organizations[selectedOrganizationIndex].avatar} />
                                    <Text>{props.organizations[selectedOrganizationIndex].name}</Text>
                                </Dropdown.Button>
                                <Dropdown.Menu direction="se">
                                {             
                                    props.organizations.map((organization, index) => (
                                        <Dropdown.Item className={(index > 0) ? ("dropdown-border") : ("")} key={index} onClick={(event) => updateOrganization(event, index)}>
                                            <Avatar mr={2} square size={24} src={organization.avatar} />
                                            <Text>{organization.name}</Text>
                                        </Dropdown.Item>              
                                    ))
                                }
                                </Dropdown.Menu>
                            </Dropdown>
                        <div />
                        <Text  className="f3">/</Text>

                        <Box mb="2" display="flex" flexDirection="row" alignItems="center">
                            <Text mr="2" className="f3" fontWeight="bold">Pipeline name</Text>
                            <CircleOcticon icon={QuestionIcon} size={18} onClick={() => openAdditionalInfo('Pipeline name', "This should be a unique name within your organization, without any spaces.")} />
                        </Box> 

                        <TextInput sx={{bg: 'text.primary', color: 'text.secondary'}} aria-label="project name" name="project name" onBlur={(e) => setName(e.target.value)}/>                 
                    </Box> 
                    <Flex mt="3" flexDirection="column" className="fill-width">
                        <Box mb="2" display="flex" flexDirection="row" alignItems="center">
                            <Text mr="2" className="f3" fontWeight="bold">Description</Text>
                            <CircleOcticon icon={QuestionIcon} size={18} onClick={() => openAdditionalInfo('Description', "An optional message you can use to describe your pipeline. It will appear under the pipeline name on the main dashboard.")} />
                        </Box> 
                        <TextInput sx={{bg: 'text.primary', color: 'text.secondary'}} onBlur={(e) => setDescription(e.target.value)}/>
                    </Flex>

                    <Text mt="6" className="h2">Repository Settings</Text>
                    <Box className="separator" />
                    <Flex mt="3" flexDirection="column" className="fill-width">
                        <Text mb="2" className="f3" fontWeight="bold">Repository URL</Text>
                        <TextInput sx={{bg: 'text.primary', color: 'text.secondary'}} placeholder="https://github.com/myteam/testrepo" onBlur={(e) => validateRepoUrl(e.target.value)} />  
                    </Flex>
                    {
                        credentialEntryContent
                    }                  

                    <Text mt="6" className="h2">Resource Paths</Text>
                    <Box className="separator" />
                    <Flex mt="3" flexDirection="column">
                        <Box display="flex" flexDirection="row" mb="2" alignItems="center">
                            <Text mr="2" className="f3" fontWeight="bold">AS Project Path</Text>
                            <CircleOcticon icon={QuestionIcon} size={18} onClick={() => openAdditionalInfo('AS Project Path', "You can figure this out by identifying where your .apj file is, and pointing to that folder. If it's in the root of your repo, then simply include the . character here.")} />
                        </Box>                
                        <Text mb="2" className="f5">Specify the path to the Automation Studio project folder within the repository.</Text>
                        <TextInput sx={{bg: 'text.primary', color: 'text.secondary'}} placeholder="AsProject" onBlur={(e) => setAsProjectPath(e.target.value)}/>
                    </Flex>
                    <Flex mt="3" flexDirection="column">
                        <Box mb="2" display="flex" flexDirection="row" alignItems="center">
                            <Text mr="2" className="f3" fontWeight="bold">Physical Configuration to Build</Text>
                            <CircleOcticon icon={QuestionIcon} size={18} onClick={() => openAdditionalInfo('Physical Configuration to Build', "Note that you can enter the same configuration for both Physical and Simulation, and Shuv will deactivate simulation during the build of the Physical config.")} />
                        </Box> 
                        <Text mb="2" className="f5">The name of the configuration in the AS project that represents the physical system.</Text>
                        <TextInput sx={{bg: 'text.primary', color: 'text.secondary'}} aria-label="project name" name="project name" placeholder="config1" onBlur={(e) => setAsPhysicalConfiguration(e.target.value)} />
                    </Flex>
                    <Flex mt="3" flexDirection="column">
                        <Box mb="2" display="flex" flexDirection="row" alignItems="center">
                            <Text mr="2" className="f3" fontWeight="bold">Simulation Configuration to Build</Text>
                            <CircleOcticon icon={QuestionIcon} size={18} onClick={() => openAdditionalInfo('Simulation Configuration to Build', "Note that you can enter the same configuration for both Physical and Simulation, and Shuv will activate simulation during the build of the Sim config.")} />
                        </Box> 
                        <Text mb="2" className="f5">The name of the configuration in the AS project that represents the simulation system.</Text>
                        <TextInput sx={{bg: 'text.primary', color: 'text.secondary'}} aria-label="project name" name="project name" placeholder="configSim" onBlur={(e) => setAsSimulationConfiguration(e.target.value)} />
                    </Flex>
                    <Box display="flex" mt="3" flexDirection="column">
                        <Box mb="2" display="flex" flexDirection="row" alignItems="center">
                            <Text mr="2" className="f3" fontWeight="bold">Revision Information Path</Text>
                            <CircleOcticon icon={QuestionIcon} size={18} onClick={() => openAdditionalInfo('Revision Information Path', "After creating the pipeline, this RevInfo.var file will be included in a list of assets you can download from Shuv and place in your AS project.")} />
                        </Box> 
                        <Text className="f5">The path to the RevInfo.var file that contains revision information.</Text>
                        <Text mb="2" className="f5">NOTE: for most cases the default value provided here is correct.</Text>
                        <TextInput sx={{bg: 'text.primary', color: 'text.secondary'}} aria-label="project name" name="project name" defaultValue={defaultRevInfoPath} onBlur={(e) => setRevInfoPath(e.target.value)} />
                    </Box>
                    <Box display="flex" mt="3" flexDirection="column">
                        <Box mt="2" display="flex" flexDirection="row" alignItems="center">
                            <input type="checkbox" id="sim-thing" name="trigger" value="true" onChange={toggleSafetyInclusion} />                       
                            <Text ml="2" className="f3" fontWeight="bold">Include Safety Application</Text>
                        </Box>    
                        <Box mt="1" display="flex" flexDirection="column">
                        {
                            (includeSafeApplication) ? (  
                                <Box display="flex" flexDirection="column">
                                    <Text mb="2" className="f5">The name of the safety application.</Text>
                                    <TextInput sx={{bg: 'text.primary', color: 'text.secondary'}} aria-label="project name" name="project name" placeholder="SafetyApp.sfapp" onBlur={(e) => setSafeApplicationName(e.target.value)} />
                                    <Text my="2" className="f5">Provide user credentials required to update the SafePLC.</Text>
                                    <Box display="flex" flexDirection="row" alignContent="center">
                                        <TextInput sx={{bg: 'text.primary', color: 'text.secondary'}} className="fill-width" mr="2" placeholder="Username" onBlur={(e) => setSafeApplicationUsername(e.target.value)} />  
                                        <TextInput sx={{bg: 'text.primary', color: 'text.secondary'}} type="password" className="fill-width" placeholder="Password" onBlur={(e) => setSafeApplicationPassword(e.target.value)} /> 
                                    </Box> 
                                </Box>
                            ) : (
                                <Box />
                            )
                        }
                        </Box>
                    </Box>

                    <Text mt="6" className="h2">Build Artifacts</Text>
                    <Box className="separator" /> 
                    <Box display="flex" mt="1" flexDirection="column">
                        <Box mt="2" display="flex" flexDirection="row" alignItems="center">
                            <input type="checkbox" id="sim-thing" name="trigger" value="true" onChange={toggleInstallerGeneration} />                       
                            <Text ml="2" className="f3" fontWeight="bold">Generate Simulation Installer</Text>
                        </Box>
                        <Box mt="1" display="flex" flexDirection="column">
                        {
                            (generateSimInstaller) ? (
                                <Box>
                                    <Box ml="5" display="flex" alignItems="center">
                                        <input type="checkbox" onChange={toggleUserFileInclusion} />                       
                                        <Box ml="2" display="flex" flexDirection="row" alignItems="center">
                                            <Text mr="2" className="f3">Include user files</Text>
                                            <CircleOcticon icon={QuestionIcon} size={18} onClick={() => openAdditionalInfo('Include user files', "If you have files in your repo that you want to include in the installer alongside the simulator, you can include them by specifying the path within the repo to these files.")} />
                                        </Box>
                                    </Box>
                                    {
                                        (includeSimInstallerUserFiles) ? (
                                            <Box ml="7" m1="3" display="flex" flexDirection="row" alignItems="center">
                                                <Text className="f5">Path to user files within repo:</Text>
                                                <TextInput sx={{bg: 'text.primary', color: 'text.secondary'}} ml="2" aria-label="user files location" name="user files location" placeholder="UserPartition" onBlur={(e) => setSimInstallerUserFiles(e.target.value)} />
                                            </Box>
                                        ) : (
                                            <Box />
                                        )
                                    }
                                    <Box ml="5" display="flex" alignItems="center">
                                        <input type="checkbox" onChange={toggleHmiFileInclusion} />                       
                                        <Box ml="2" display="flex" flexDirection="row" alignItems="center">
                                            <Text mr="2" className="f3">Include external HMI files</Text>
                                            <CircleOcticon icon={QuestionIcon} size={18} onClick={() => openAdditionalInfo('Include external HMI files', "This is specifically for web-based HMIs that are hosted separately from the PLC. If you are using MappView, you can leave this box unchecked.")} />
                                        </Box>
                                    </Box>
                                    {
                                        (includeSimInstallerHmiFiles) ? (
                                            <Box ml="7" m1="3" display="flex" flexDirection="row" alignItems="center">
                                                <Text className="f5">Path to HMI files within repo:</Text>
                                                <TextInput sx={{bg: 'text.primary', color: 'text.secondary'}} ml="2" aria-label="HMI files location" name="HMI files location" placeholder="HMIApp/Electron" onBlur={(e) => setSimInstallerHmiFiles(e.target.value)} />
                                            </Box>
                                        ) : (
                                            <Box />
                                        )
                                    }
                                </Box>
                            ) : (
                                <Box />
                            )
                        }  
                        </Box> 
                    </Box>

                    <Text mt="6" className="h2">Triggers</Text>
                    <Box className="separator" />   
                    <Flex mt="3" flexDirection="column">
                        <Text mb="2" className="f3" fontWeight="bold">Build Trigger</Text>
                        <Box>
                            <Box display="flex" alignItems="center">
                                <input disabled type="radio" id="trigger-tag" name="trigger" value="tag" onChange={(e) => setTrigger(e.target.value)} />
                                <StyledOcticon color="icon.secondary" ml="2" icon={TagIcon} size={20} />
                                <Box ml="1" display="flex" flexDirection="column" alignItems="flex-start">
                                    <Text color="text.disabled" fontWeight="bold">Tag</Text>
                                    <Text color="text.disabled" className="f5">Trigger every time a tag is pushed.</Text>
                                </Box>   
                            </Box>
                            <Box mt="2" display="flex" alignItems="center">
                                <input type="radio" id="trigger-branch" name="trigger" value="branch" onChange={(e) => setTrigger(e.target.value)} />
                                <StyledOcticon ml="2" icon={GitBranchIcon} size={20} />
                                <Box ml="1" display="flex" flexDirection="column">
                                    <Text fontWeight="bold">Branch</Text>
                                    <Text className="f5">Trigger every time a commit is pushed to the release branch.</Text>
                                </Box>     
                            </Box>                    
                        </Box>
                        {
                            (trigger === "branch") ? (
                                <Flex mt="3" flexDirection="column">

                                    <Box mb="2" display="flex" flexDirection="row" alignItems="center">
                                        <Text mr="2" className="f3" fontWeight="bold">Release Branch Name</Text>
                                        <CircleOcticon icon={QuestionIcon} size={18} onClick={() => openAdditionalInfo('Release Branch Name', "This is the name of the branch that will be used as a trigger source for Shuv. Any push to this branch will cause this pipeline to execute.")} />
                                    </Box>
                                    <TextInput sx={{bg: 'text.primary', color: 'text.secondary'}} aria-label="project name" name="project name" placeholder="main" onBlur={(e) => setBranch(e.target.value)} />
                                </Flex>  
                            ) : (
                                <div />
                            )
                        }
                    </Flex>

                    <Box mt="4">
                        <ButtonPrimary variant="large" onClick={createProject}>{projectButtonText}</ButtonPrimary>
                    </Box>
                    <Box my="4">
                    {
                        (errorMsg !== '') ? (
                            <Flash variant="danger">
                                Error: {errorMsg}
                            </Flash>
                        ) : (
                            <div>
                            {
                                (projectCreationStatus !== '') ? (
                                    <Flash variant="default">
                                        <Spinner size="small" />
                                        {projectCreationStatus}
                                        <ProgressBar 
                                            mt="3" 
                                            progress={(time - progressStartTime)/(stepTime / 100)}
                                            bg="bg.infoInverse"
                                        />
                                    </Flash> 
                                ) : (
                                    <div />
                                )
                            }  
                            </div>    
                        )
                    }    
                    </Box> 

                </Flex>
                <MessagePopup 
                    open={popupOpen} 
                    closePopupCb={(e) => setPopupOpen(false)} 
                    titleJsx={
                        <Text fontWeight="bold">{popupTitle}</Text>
                    }
                    bodyJsx={
                        <Text>{popupInfo}</Text>
                    }
                />
            </Flex> 
        )
    }

    return (  
        <Box>
        {
            projectCreationContent()
        }   
        </Box>      
    )          
}

export default NewProjectPage;