import React, { useState, useEffect } from 'react';
import './Styles/Primer.scss';
import './Styles/App.css';
import OrganizationPage from './Pages/OrganizationPage.js';
import MachineDetailPage from './Pages/MachineDetailPage.js';
import ProjectDetailPage from './Pages/ProjectDetailPage.js';
import LoginPage from './Pages/LoginPage.js';
import SignUpPage from './Pages/SignUpPage.js';
import LoadingPage from './Pages/LoadingPage.js';
import ErrorPage from './Pages/ErrorPage.js';
import NewProjectPage from './Pages/NewProjectPage.js';
import UserSettingsPage from './Pages/UserSettingsPage.js';
import TopBar from './Components/TopBar.js';
import MessagePopup from './Components/MessagePopup.js';
import { onAuthUIStateChange, AuthState, CognitoUserInterface } from '@aws-amplify/ui-components';
import { Button, ThemeProvider, theme, Text, StyledOcticon, Box, ButtonInvisible } from '@primer/components';
import deepmerge from 'deepmerge'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    withRouter,
    useHistory,
    useLocation
  } from "react-router-dom";
import { API, graphqlOperation, Auth, Analytics } from 'aws-amplify';
import { Container } from 'react-bootstrap';
import { BroadcastIcon, ContainerIcon } from '@primer/octicons-react';

const lightTheme = 'light';
const darkTheme = 'dark';

// Customize the existing theme to make it more Loupe-esque.
const customTheme = deepmerge(theme, {
    colorSchemes: {
        dark: {
            colors: {
                text: {
                    primary: 'white',
                    secondary: 'black',
                    tertiary: 'black',
                    placeholder: 'grey',
                    disabled: 'grey'
                },
                border: {
                    primary: 'white',
                    secondary: 'white',
                    tertiary: 'white',
                    overlay: 'white'
                },
                bg: {
                    canvas: 'black',
                    primary: 'black',
                    secondary: 'black',
                    tertiary: 'black',
                    overlay: 'black',
                    backdrop: 'black'
                }
            }
        }
    }
})

function App() {

    let history = useHistory();
    let location = useLocation();

    /////////////////////////////////////////////////////////////////////
    // Auth functions.
    /////////////////////////////////////////////////////////////////////
    const [authState, setAuthState] = useState(AuthState.SignedOut);
    const [user, setUser] = useState();
    const userRef = React.useRef();
    userRef.current = user;
    const [userPreferences, setUserPreferences] = useState({ theme: "dark" });
    // Handle changes in the authentication state. 
    useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            console.log('Next state is ' + nextAuthState)
            console.log('User data', authData)
            setAuthState(nextAuthState);
            setUser(authData);
            if (nextAuthState === AuthState.SignedIn) {
                fetchOrganizations(authData);
                fetchUserPreferences(authData);
            }
        });
    }, []);
    // Loading timer (must elapse before we check auth state).
    const [loadDone, setLoadDone] = useState(false);
    useEffect(() => {
        const interval = setInterval(() => setLoadDone(true), 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);
    async function fetchUserPreferences(localUser) {
        var localOrgs = [];
        console.log('Fetching user preferences...')
        if (localUser) {
            const query =  `
                query userByUsername {
                    userByUsername(username: "` + localUser.username + `") {
                        items {
                            id
                            avatar
                            theme
                            username
                        }
                    }
                }
            `;
            var response = await API.graphql(graphqlOperation(query));
            // Insert Robohash if no avatar was specified. 
            if (response.data.userByUsername.items.length > 0) {
                if (response.data.userByUsername.items[0].avatar === '') {
                    response.data.userByUsername.items[0].avatar = 'https://robohash.org/' + response.data.userByUsername.items[0].email + '?gravatar=yes';
                }
                setUserPreferences(response.data.userByUsername.items[0]);
            } else {
                console.log('No user preferences found');
                setError('This user was not set up correctly. Please sign in again with a different account.')               
            }
        }
    }
    async function updateUserPreferences(preferences) {
        console.log('Updating user preferences')
        var inputPayload = {
            id: userPreferences.id
        }
        if (preferences.avatar) {
            inputPayload.avatar = preferences.avatar;
        }
        if (preferences.theme) {
            inputPayload.theme = preferences.theme;
        }
        const updateUser = `
            mutation UpdateUser($input: UpdateUserInput!) {
                updateUser(input: $input) {
                    id
                }
            }
        `
        var response = await API.graphql(graphqlOperation(updateUser, { input: inputPayload }));
        fetchUserPreferences(user);
    }

    /////////////////////////////////////////////////////////////////////
    // Theme data.
    /////////////////////////////////////////////////////////////////////
    const [colorModeComponent, setColorModeComponent] = useState();
    const [colorModeCss, setColorModeCss] = useState();
    useEffect(() => {
        if (userPreferences) {
            if (userPreferences.theme === "dark") {
                setColorModeComponent('night');
                setColorModeCss('dark');
            } else {
                setColorModeComponent('day');
                setColorModeCss('light');
            }
        }
    },[userPreferences]);

    /////////////////////////////////////////////////////////////////////
    // Organization functions.
    /////////////////////////////////////////////////////////////////////
    const [filePath, setFilePath] = useState('userData/');
    const [organizations, setOrganizations] = useState([]);
    const organizationsRef = React.useRef();
    organizationsRef.current = organizations;
    const [activeOrganizationIndex, setActiveOrganizationIndex] = useState(0);
    async function fetchOrganizations(localUser) {
        var localOrgs = [];
        console.log('Fetching...')
        if (localUser) {
            const idToken = localUser.signInUserSession.idToken.payload;
            console.log('ID token', idToken)
            const groups = idToken['cognito:groups']
            console.log('Group info', groups)
            if (groups && groups.length > 0) {   
                // Loop through all groups that the user belongs to, and grab their organization info. 
                for (var i = 0; i < groups.length; i++) {
                    const query =  `
                        query OrganizationByName {
                            organizationByName(name: "` + groups[i] +`") {
                                items {
                                    id
                                    name
                                    createdAt
                                    updatedAt
                                    avatar
                                    website
                                    location
                                    machines {
                                        items {
                                            id
                                            name
                                            description
                                            online
                                            deploymentStatus
                                            latestBuiltVersion
                                            machineVersion
                                            longitude
                                            latitude
                                            updateTime
                                            project {
                                                id
                                                alias
                                                type
                                                branch
                                                repository
                                                physicalConfiguration
                                                simulationConfiguration
                                                generateSimInstaller
                                                simInstallerUserFiles
                                                simInstallerHmiFiles
                                                pipeline
                                                description
                                                asProject
                                                revInfoPath
                                                trigger
                                                organization {
                                                    name
                                                }
                                                latestBuildID
                                                latestBuild {
                                                    commit
                                                    tag
                                                    status
                                                    stage
                                                    phase
                                                    buildUrl
                                                    pipelineUrl
                                                    version
                                                    startTime
                                                }
                                                repositoryType
                                            }
                                        }
                                    }
                                    projects {
                                        items {
                                            id
                                            alias
                                            type
                                            branch
                                            repository
                                            physicalConfiguration
                                            simulationConfiguration
                                            generateSimInstaller
                                            simInstallerUserFiles
                                            simInstallerHmiFiles
                                            pipeline
                                            description
                                            asProject
                                            revInfoPath
                                            trigger
                                            organization {
                                                name
                                            }
                                            latestBuildID
                                            latestBuild {
                                                commit
                                                tag
                                                status
                                                stage
                                                phase
                                                buildUrl
                                                pipelineUrl
                                                version
                                                startTime
                                            }
                                            repositoryType
                                        }
                                    }
                                }
                            }
                        }
                    `;
                    var response = await API.graphql(graphqlOperation(query));
                    // Insert Robohash if no avatar was specified. 
                    response.data.organizationByName.items.forEach((item, index) => {
                        if (item.avatar === '') {
                            item.avatar = 'https://robohash.org/' + item.name;
                        }
                    })
                    localOrgs.push(response.data.organizationByName.items[0])
                }
                console.log('Organizations', localOrgs)
                setOrganizations(localOrgs);
            } else {
                // try {
                //     await Auth.signOut();
                // } catch (error) {
                //     console.log('error signing out: ', error);
                // }
                setError('This user is not associated with any organizations yet.')
            }
        }
    }
    function updateOrganizations() {
        fetchOrganizations(user);
    }
    function updateActiveOrganizationPath(event, index, history) {
        history.push("/" + organizations[index].name)
     //   setActiveOrganizationIndex(index);
    }
    function updateActiveOrganizationIndex(activeOrg) {
        organizations.forEach((organization, index) => {
            if (organization.name === activeOrg) {
                setActiveOrganizationIndex(index)
            }
        })
    }
    
    /////////////////////////////////////////////////////////////////////
    // Machine subscription functions.
    /////////////////////////////////////////////////////////////////////
    useEffect(() => {
        subscribeToMachineCreation();
    },[])
    function onCustomMachineCreate(value) {    
        console.log('A new machine was created!', value)  
        console.log('The machine name is ' + value.data.onCreateMachine.name)
        const titleJsx = [];
        titleJsx.push(<Text className="h3">New Machine Available!</Text>);
        const bodyJsx = [];
        bodyJsx.push(<StyledOcticon color="icon.success" icon={BroadcastIcon} className='icn-bounce' size={36}/>)
        bodyJsx.push(<Text ml="2">The {value.data.onCreateMachine.name} machine</Text>);
        bodyJsx.push(<Text ml="1">has just connected to the cloud for the first time.</Text>);
        setMessagePopupTitleJsx(titleJsx);
        setMessagePopupBodyJsx(bodyJsx);
        setOpenMessagePopup(true);
        fetchOrganizations(userRef.current);
    }
    async function subscribeToMachineCreation() {
        const onCreateMachine =  `
            subscription OnCreateMachine {
                onCreateMachine {
                    id
                    name
                    organizationName
                }
            }
        `
        const subscription = await API.graphql(
            graphqlOperation(onCreateMachine)
        ).subscribe({
            next: ({ provider, value }) => onCustomMachineCreate(value),
            error: error => console.warn(error)
        })
    }

    /////////////////////////////////////////////////////////////////////
    // Global error handling functions.
    /////////////////////////////////////////////////////////////////////
    const [error, setError] = useState('');

    /////////////////////////////////////////////////////////////////////
    // Message popup handling functions.
    /////////////////////////////////////////////////////////////////////
    const [openMessagePopup, setOpenMessagePopup] = useState();
    const [messagePopupTitleJsx, setMessagePopupTitleJsx] = useState([]);
    const [messagePopupBodyJsx, setMessagePopupBodyJsx] = useState([]);
    function closeMessagePopupCb() {
        setOpenMessagePopup(false);
    }
    function openMessagePopupCb() {
        setOpenMessagePopup(true);
    }

    function determineAuthPage() {
        if (loadDone) {
            switch(authState) {
                case AuthState.SignIn:
                case AuthState.ConfirmSignIn:
                case AuthState.VerifyingAttributes:
                case AuthState.ForgotPassword:
                case AuthState.ResetPassword:
                case AuthState.SettingMFA:
                case AuthState.VerifyContact:
                case AuthState.SignedOut:
                case AuthState.SignUp:
                case AuthState.SigningUp:
                case AuthState.ConfirmSignUp:
                    return <LoginPage authState={authState} user={user} resetCb={resetAppState} />
                default:
                    <LoadingPage />
            }
        } else {
            return <LoadingPage />
        }
    }

    /////////////////////////////////////////////////////////////////////
    // Functions for clearing out the state of the app.
    /////////////////////////////////////////////////////////////////////
    function resetAppState() {
        console.log('Resetting the app state');
        setUser();
        setUserPreferences();
        setOrganizations([]);
        setActiveOrganizationIndex(0);
        setError('');
        setOpenMessagePopup();
        setMessagePopupTitleJsx([]);
        setMessagePopupBodyJsx([]);
    }

    return (
        <ThemeProvider colorMode={colorModeComponent} dayScheme={lightTheme} nightScheme={darkTheme} theme={customTheme}>            
            <Router>
                <div className="app" data-color-mode={colorModeCss} data-light-theme={lightTheme} data-dark-theme={darkTheme}>
                    {
                        ((authState === AuthState.SignedIn) && (user)) ? (   
                            <div>                                
                                <TopBar 
                                    user={user} 
                                    userPreferences={userPreferences}
                                    organizations={organizations}
                                    activeOrganizationIndex={activeOrganizationIndex}
                                    updateActiveOrganizationPathCb={updateActiveOrganizationPath}    
                                />
                                {
                                    ((organizations[0]) && (!error)) ? (
                                
                                        <Switch>
                                            <Route path="/settings">
                                                <UserSettingsPage 
                                                    user={user}
                                                    userPreferences={userPreferences}
                                                    organizations={organizations}
                                                    activeOrganizationIndex={activeOrganizationIndex}
                                                    updateUserPreferencesCb={updateUserPreferences}
                                                />
                                            </Route>
                                            <Route path="/:activeOrg/machines/:activeMachine">    
                                                <MachineDetailPage 
                                                    organization={organizations[activeOrganizationIndex]}
                                                    user={user}
                                                    machines={organizations[activeOrganizationIndex].machines.items}
                                                    updateActiveOrganizationIndexCb={updateActiveOrganizationIndex}
                                                    updateOrganizationsCb={updateOrganizations}
                                                />
                                            </Route>
                                            <Route path="/:activeOrg/pipelines/:activeProject">
                                                <ProjectDetailPage
                                                    user={user}
                                                    organization={organizations[activeOrganizationIndex]}
                                                    machines={organizations[activeOrganizationIndex].machines.items}
                                                    projects={organizations[activeOrganizationIndex].projects.items}
                                                    filePath={filePath}
                                                    updateActiveOrganizationIndexCb={updateActiveOrganizationIndex}
                                                    updateOrganizationsCb={updateOrganizations}
                                                />
                                            </Route>
                                            <Route path="/:activeOrg/newpipeline">
                                                <NewProjectPage 
                                                    organizations={organizations}
                                                    updateOrganizationsCb={updateOrganizations}
                                                    filePath={filePath}
                                                    activeOrganizationIndex={activeOrganizationIndex}
                                                    updateActiveOrganizationIndexCb={updateActiveOrganizationIndex}
                                                />
                                            </Route>
                                            <Route path="/:activeOrg">
                                                <OrganizationPage
                                                    user={user}
                                                    organization={organizations[activeOrganizationIndex]}
                                                    machines={organizations[activeOrganizationIndex].machines.items}
                                                    projects={organizations[activeOrganizationIndex].projects.items}
                                                    updateActiveOrganizationIndexCb={updateActiveOrganizationIndex}
                                                />
                                            </Route>
                                            <Route>
                                                <Redirect to={"/" + organizations[activeOrganizationIndex].name} />
                                            </Route>
                                        </Switch>    
                                    ) : (
                                        <div>
                                        {
                                            (error !== '') ? (
                                                <ErrorPage errorMsg={error} />
                                            ) : (
                                                <LoadingPage />
                                            )
                                        }
                                        </div>
                                    )
                                }
                            </div>
                        ) : (
                            <Switch>
                                <Route exact path="/">
                                    <SignUpPage />
                                </Route>
                                <Route>
                                    {determineAuthPage()}                         
                                </Route>       
                            </Switch>
                        )
                    }
                    <MessagePopup 
                        open={openMessagePopup} 
                        closePopupCb={closeMessagePopupCb} 
                        titleJsx={messagePopupTitleJsx}
                        bodyJsx={messagePopupBodyJsx}
                    />
                </div>
            </Router>
        </ThemeProvider>
    );
}

export default withRouter(App);