import '../Styles/App.css';
import '../Styles/Primer.scss';
import Machine from '../Components/Machine.js';
import Project from '../Components/Project.js';
import { ReactComponent as DarkPreview } from '../Media/dark-dimmed-preview.svg';
import { ReactComponent as LightPreview } from '../Media/light-preview.svg';
import '../Styles/Bootstrap.scss';
import { Button, Breadcrumb, Pagehead, Text, Flex, Header, StyledOcticon, Avatar, SideNav, Dropdown, TextInput, Link as PrimerLink, CounterLabel, ButtonPrimary, FilteredSearch, Box } from '@primer/components';
import { LinkIcon, LocationIcon, SyncIcon, SearchIcon, ContainerIcon, PlugIcon } from '@primer/octicons-react';
import { useRouteMatch, Link, Switch, Route, withRouter, useParams, Redirect } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Analytics } from 'aws-amplify';

function UserSettingsPage(props){

    let { path, url } = useRouteMatch();

    useEffect(() => {
        Analytics.record({ 
            name: 'pageChange',
            attributes: {
                user: props.user.username,
                page: 'User Settings Page'
            }
        })
    }, [])

    return (
        <div>
            <Pagehead py="0" my="2">
                <Header bg="bg.canvas">
                    <Header.Item>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Avatar mr={2} size={100} src={props.userPreferences.avatar} />
                            <Box ml="3" display="flex" flexDirection="column">
                                <Text className="h1">{props.user.username}</Text>       
                                <Text className="f3">Your personal account</Text>                    
                            </Box>
                        </Box>                                         
                    </Header.Item>
                    <Header.Item full></Header.Item>
                </Header>
            </Pagehead>
            
            <Box>
                <Box display="flex" alignItems="flex-start">
                    <SideNav m="4" bordered>
                        <SideNav.Link selected={props.location.pathname === `${url}/profile`}>
                            <Link to={`${url}`}>
                                <Text fontSize="2" color="text.primary">Profile</Text>
                            </Link>
                        </SideNav.Link>
                        <SideNav.Link selected={props.location.pathname === `${url}/appearance`}>
                            <Link to={`${url}/appearance`}>
                                <Text fontSize="2" color="text.primary">Appearance</Text>
                            </Link>
                        </SideNav.Link>
                    </SideNav>
                    <Switch>
                        <Route path={`${path}/profile`}>
                            <UserProfileSettings 
                                userPreferences={props.userPreferences} 
                                user={props.user} 
                                updateUserPreferencesCb={props.updateUserPreferencesCb}
                            />
                        </Route>
                        <Route path={`${path}/appearance`}>
                            <UserAppearanceSettings 
                                userPreferences={props.userPreferences} 
                                user={props.user} 
                                updateUserPreferencesCb={props.updateUserPreferencesCb}
                            />
                        </Route>
                        <Route>
                            <Redirect to={"/settings/profile"} />
                        </Route>
                    </Switch>
                </Box>
            </Box>                           
        </div>
    )
}

function UserProfileSettings(props) {

    const [profilePicture, setProfilePicture] = useState(props.userPreferences.avatar);
    const [username, setUsername] = useState(props.user.username);
    const [email, setEmail] = useState(props.user.attributes.email);
    const [phoneNumber, setPhoneNumber] = useState(props.user.attributes.phone_number);

    function restoreDefaultProfilePicture() {
        updateProfilePicture('https://robohash.org/' + props.user.attributes.email + '?gravatar=yes');
    }

    function updateProfilePicture(value) {
        console.log('Updating profile picture')
        const preferences = {
            avatar: value
        }
        props.updateUserPreferencesCb(preferences)
        setProfilePicture(value)
    }

    return (
        <Box display="flex" mr="2" mt="2" flexDirection="column" alignItems="flex-start" className="user-settings">
            <Text pt="2" pb="2" className="h2 border-bottom fill-width">Your credentials</Text>
            <Box display="flex" mt="1" flexDirection="column" className="fill-width">
                <Text mb="2" className="f3" fontWeight="bold">Username</Text>
                <TextInput readOnly={true} defaultValue={props.user.username} onBlur={(e) => setUsername(e.target.value)}/>
            </Box>
            <Box display="flex" mt="1" flexDirection="column" className="fill-width">
                <Text mb="2" className="f3" fontWeight="bold">Email</Text>
                <TextInput readOnly={true} defaultValue={props.user.attributes.email} onBlur={(e) => setEmail(e.target.value)}/>
            </Box>
            <Box display="flex" mt="1" flexDirection="column" className="fill-width">
                <Text mb="2" className="f3" fontWeight="bold">Phone number</Text>
                <TextInput readOnly={true} defaultValue={props.user.attributes.phone_number} onBlur={(e) => setPhoneNumber(e.target.value)}/>
            </Box>
            <Text pt="5" pb="2" className="h2 border-bottom fill-width">Your profile picture</Text>
            <Box display="flex" flexDirection="column" className="fill-width">
                <Text mb="2" className="f3" fontWeight="bold">Picture URL</Text>
                <TextInput defaultValue={profilePicture} onBlur={(e) => updateProfilePicture(e.target.value)}/>   
                <Box mt="2" display="flex" flexDirection="row">
                    <Text whiteSpace="nowrap" mr="1">You can restore default behavior</Text>
                    <Text as={PrimerLink} onClick={restoreDefaultProfilePicture}>here</Text>
                    <Text whiteSpace="nowrap" ml="1">(i.e. Gravatar lookup based on e-mail, with robohash fallback).</Text>
                </Box>
                                     
            </Box>
            
        </Box> 
    )

}

function UserAppearanceSettings(props) {

    function updateTheme(value) {
        console.log('Updating theme')
        const preferences = {
            theme: value
        }
        props.updateUserPreferencesCb(preferences)
    }

    return (
        <Box display="flex" mr="2" mt="2" flexDirection="column" alignItems="flex-start" className="user-settings">
            <Text pt="2" pb="2" className="h2 border-bottom fill-width">Theme preferences</Text>
            <Text mt="2">Choose your preferred display theme for Shuv.</Text>
            <Box mt="3" display="flex" flexDirection="row" alignItems="center">
                <Box display="flex" flexDirection="column" onClick={() => { updateTheme("dark") }}>
                    <Text mb="2" className="f3">Dark dimmed</Text>
                    <DarkPreview className={(props.userPreferences.theme === "dark") ? ("theme-helper-img-selected") : ("theme-helper-img")}/>
                </Box>
                <Box ml="2" display="flex" flexDirection="column" onClick={() => { updateTheme("light") }}>
                    <Text mb="2" className="f3">Light</Text>
                    <LightPreview className={(props.userPreferences.theme !== "dark") ? ("theme-helper-img-selected") : ("theme-helper-img")}/>
                </Box>   
            </Box>
            
        </Box> 
    )

}

export default withRouter(UserSettingsPage);