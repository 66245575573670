import '../Styles/App.css';
import '../Styles/Primer.scss';
import '../Styles/Bootstrap.scss';
import { Box, Text, StyledOcticon, Link } from '@primer/components';
import ShuvLogo from '../Media/shuv-logo.png';
import { AlertIcon } from '@primer/octicons-react';
import { AmplifySignOut } from '@aws-amplify/ui-react';

function ErrorPage(props){

    return (
        <Box mt="5" p="5" display="flex" flexDirection="column" alignItems="center">
            <StyledOcticon icon={AlertIcon} size={35} />
            <Text mt="3" as="h3" textAlign="center" mb="1">{props.errorMsg}</Text>
            <Box mt="3" display="flex"> 
                <AmplifySignOut buttonText="Sign Out"></AmplifySignOut>
            </Box>
        </Box>
    )
}

export default ErrorPage;